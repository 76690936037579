// Enums

export enum GuaranteeType{
    Lease = 'Lease / Rental Bond / Commercial Lease',
    Advance = 'Advance Payment Guarantee',
    Bid = 'Bid / Tender Bond',
    Credit = 'Credit Security Bond',
    Defects = 'Defects & Liabilities',
    Deposit = 'Deposit Bond',
    Environmental = 'Environmental Guarantee',
    Facility = 'Facility Guarantee',
    Maintenance = 'Maintenance Bond',
    Payment = 'Payment Guarantee',
    Performance = 'Performance Bond',
    Practical = 'Practical Completion Guarantee',
    Security = 'Security for Contract Performance',
    Warranty = 'Warranty Period Bond',
    Workcover = 'Workcover Guarantee',
    Bill = 'Bill of Lading / Shipping Guarantee'
}
export enum Currencies{
    AUD = 'AUD',
    USD = 'USD',
    EUR = 'EUR',
    HKD = 'HKD',
    JPY = 'JPY',
    GBP = 'GBP'
}
export enum GuaranteeStatus{
    New = 'New',
    Review = 'Review',
    Agreed = 'Agreed',
    Issued = 'Issued',
    Cancelled = 'Cancelled'
}

//Old
export enum DataRequestStatus {
    RESPONDED = 'RESPONDED',
    REQUESTED = 'REQUESTED',
    DECLINED = 'DECLINED',
    DELETION = 'DELETION',
    DELETED = 'DELETED'
}

export enum RequestPermissions {
    VERIFIED = 'VERIFIED',
    VIEW = 'VIEW',
    DOWNLOAD = 'DOWNLOAD'
}

export enum BadgeTypes {
    INVALID = 'INVALID',
    LOW = 'LOW',
    HIGH = 'HIGH'
}

export enum InvitationStatus {
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    WAITING = 'WAITING'
}

export enum EmployeeStatus {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    WORKER = 'WORKER',
    DENIED = 'DENIED'
}

export enum FrankieOneDocumentTypes {
    PASSPORT = 'PASSPORT',
    DRIVERS_LICENCE = 'DRIVERS_LICENCE',
    NATIONAL_ID = 'NATIONAL_ID',
    NATIONAL_HEALTH_ID = 'NATIONAL_HEALTH_ID'
}

export enum PersonalDataTypes {
    NAME = 'NAME',
    DOB = 'DOB',
    ADDRESS = 'ADDRESS'
}

export enum CustomDataTypes {
    PHOTO_ID = 'PHOTO_ID',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    FILE_UPLOAD = 'FILE_UPLOAD'
}

export const PointThreshold = 130;

export const VerifiedDocumentTypes = [
    FrankieOneDocumentTypes.PASSPORT, 
    FrankieOneDocumentTypes.DRIVERS_LICENCE, 
    // FrankieOneDocumentTypes.NATIONAL_ID, 
    FrankieOneDocumentTypes.NATIONAL_HEALTH_ID
];

export const adminURL = process.env.ADMIN_URL;

export const prompt_cost = 0.048 / 3000;
export const completion_cost = 0.095 / 2000;