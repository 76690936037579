
 

import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation

const clientId = "93f3fe31-4175-4953-a686-0253cf563b2e";

const removeAfterHash = (str:string) => {
    const hashIndex = str.indexOf('#');
    if (hashIndex === -1) { // '#' not found
        return str;
    }
    return str.substring(0, hashIndex);
}

export const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: "https://sso.notcentralised.com/1580ea85-41b7-4ec8-8967-abfe2a0a8349/B2C_1_susi",
        knownAuthorities: ["sso.notcentralised.com"],
        navigateToLoginRequestUrl: true,

        redirectUri: removeAfterHash(window.location.href),
        postLogoutRedirectUri: removeAfterHash(window.location.origin)

    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
      cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "offline_access", clientId],
    redirectUri: removeAfterHash(window.location.href)
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const graphMembersConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf"
};