// App.tsx

import React, { useState, useRef, useEffect, useReducer } from 'react';

import { Alert, Spin, Menu, Avatar, Select, Popconfirm, Tag, TreeSelect, Divider, Modal, Card, Tooltip, FloatButton, Input, Button, List, Form, Table, Space, Tabs, message } from 'antd';
import type { UploadProps } from 'antd';
import type { MenuProps } from 'antd';

import { RedoOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, TeamOutlined, SafetyCertificateOutlined, SearchOutlined, FilePdfOutlined, FileWordOutlined, CopyOutlined, DownOutlined, UpOutlined, SendOutlined, MessageOutlined, LoadingOutlined, DeleteOutlined} from '@ant-design/icons';

import { Employees } from '../employees/Employees';

import { isMobileBrowser } from '../../utils/utils';
import { prompt_cost, completion_cost } from '../../utils/constants';

import hljs from 'highlight.js';
import { marked } from 'marked';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import axios from 'axios';

const { TextArea } = Input;
const { Item } = Form;

const { CheckableTag } = Tag;

const { Option } = Select;

const renderMarkdown = (markdownContent: string) => {
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

type MenuItem = Required<MenuProps>['items'][number];

interface Message {
    id: string;
    author: string;
    timestamp: Date;
    input: string;
    content: string;
    data: any;
    show: boolean;
    prompt_tokens?: number;
    completion_tokens?: number;
    docsearch?: string;
    tags?: any;
    agent_reference: string;
    model: string;
    temperature: number;
    signature?: any;
}
const IconText = ({ icon, text, onClick, color }: { icon: React.FC; text?: string; onClick?: any; color?:string }) => (
    <a style={{color: 'grey'}}>
        <Tooltip title= {text}>
            <Space onClick={onClick||undefined}>
                <span style={{ 
                    fontSize: '24px',
                    color: color
                }}>
                    <div style={{ transform: 'translateY(40px)' }}>
                        {React.createElement(icon)}
                    </div>
                </span>
            </Space>
        </Tooltip>
    </a>
);

const uniqueObjects = (objects: any[]) => objects?.reduce((unique, obj) => {
    if (!unique.some((o:any) => o.name === obj.name)) {
      unique.push(obj);
    }
    return unique;
}, [] as any[]);


const isValidJson = (jsonString?: string): boolean => {
    try {
        if(!jsonString) return false;

        JSON.parse(jsonString);
        return true;
    } catch (e) {
          return false;
    }
  }
  

const MessageTree : Record<string,  Message[]> = {}

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                            e.stopPropagation();
                            axios.post(
                                `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                                {
                                    name: editValue
                                },
                                {
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(res => {
                                setIsEditing(false);
                            });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: e.target.value
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: editValue
                            },
                            {
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    paddingLeft: parent.selectedThread === parent.keyVal ? '7px' : undefined, // adjust padding if needed when selected
                    borderLeft: parent.selectedThread === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedThread === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:<MessageOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_thread' ? '' : '10px' }}
                    onClick={(e)=>{
                        
                        if(parent.keyVal === 'add_thread')
                            parent.handleAddClick(e);
                        else{
                            parent.setThread(parent.keyVal);
                        }
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                :
                    <>
                    <Button
                        type="text"
                        icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent the menu from triggering its default behavior
                            setIsEditing(true);
                        }}
                    />
                    { parent.selectedThread === parent.keyVal ? 
                    <Popconfirm
                        title="Delete Thread"
                        description="Would you like to delete the thread?"
                        onConfirm={(e) => {
                            // e.stopPropagation(); // Prevent the menu from triggering its default behavior
                            setIsEditing(false);

                            axios.post(
                                `${azure_function_url}/LLMModel?command=context_thread_delete&context=${parent.context}&thread=${parent.keyVal}`,
                                {
                                    name: editValue
                                },
                                {
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(res => {
                                console.log(res);
                                setIsEditing(false);
                                parent.reload();
                            }).catch(err => {console.log(err);message.error(err.toString())});
                        }}
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        
                    />
                    </Popconfirm>
                    :<></>
                    }
                    </>
                }
            </div>
    );
};


export const KeyDocuments: React.FC<any> = (parent) => {

    const [thread, setThread] = useState('1');

    const [input, setInput] = useState('');
    const [tags, setTags] = useState();
    const [agent, setAgent] = useState('chat');
    const [tagTree, setTagTree] = useState<any>([]);
    const [fileList, setFileList] = useState<any[]>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [learnStates, setLearnStates] = useState<any[]>();
    const [openMessage, setOpenMessages] = useState<Message>();
    
    const [isLoadingChat, setLoadingChat] = useState({ state: false });

    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const messagesTopRef = useRef<HTMLDivElement | null>(null);
    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const listContainerRef = useRef<HTMLDivElement | null>(null);

    const [isMobile, _] = useState<boolean>(isMobileBrowser());
    const [activeTabKey, setActiveTabKey] = useState('1'); // Default active tab key
    
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [openKeys, setOpenKeys] = useState(['1']);

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const loadThreadList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=context_thread_list&context=${parent.context}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                const it = res.data.map((x:any) => getItem(x.name, x.thread));
                it.push(getItem('New Thread', 'add_thread', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                if(set_thread && res.data.length > 0)
                    setThread(res.data[0].thread);
        }).catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_thread', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_thread';
        
        return {
            key,
            label: <InputLabel bearerToken={parent.bearerToken} context={parent.context} label={label} keyVal={key} selectedThread={thread} isAddThread={isAddThread} handleAddClick={handleAddClick} setThread={setThread} reload={loadThreadList}/>,
            type,
        } as MenuItem;
    }
    
      // Function to add a new item on top of Add Thread
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_thread') {
            console.log(num)
            // const num = menuItems.length
            const name = `Thread ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id);

            axios.post(
                `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${id}`,
                {
                    name: name
                },
                {
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(res => {
                setMenuItems((prevItems) => {
                    // Find the index of 'Add Thread'
                    const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_thread');
                    // Insert new item just above 'Add Thread'
                    const newItems = [...prevItems];
                    newItems.splice(addThreadIndex, 0, newItem);
                    return newItems;
                });
            }).catch(err => {console.log(err);message.error(err.toString())});       
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        messageInputRef.current?.focus();

        const element = listContainerRef.current;
        if (element) {
            setTimeout(() => {
                element.scrollTop = element.scrollHeight;
            }, 0);
        }
    };

    const scrollToTop = () => {
        messagesTopRef.current?.scrollIntoView({ behavior: "smooth" });  
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    // const gptModel = 'gpt-4-1106-preview';
    const gptModel = 'gpt-4';

    const prompts: Record<string, any> = {
        chat: {
            label: 'Chat',
            template:`
                You are a conversational chat agent.
                ------
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 0
        },
        researcher: {
            label: 'Researcher',
            template:`
                You are a researcher agent that writes research documents with titles, summaries and conclusions.
                ------
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 0
        },
        pirate: {
            label: 'Pirate',
            template:`
                You are a Pirate agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                All of the output must be written in the style of a Pirate.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        gangsta: {
            label: 'Gangsta',
            template:`
                You are a Gangsta Rapper agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                All of the output must be written in the style of a Gangsta Rapper.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        alig: {
            label: 'Ali G',
            template:`
                You are an Ali G agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                All of the output must be written in the offensive style of Ali G.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        borat: {
            label: 'Borat',
            template:`
                You are a Borat agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                All of the output must be written in the offensive style of Borat.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        shakespeare: {
            label: 'Shakespeare',
            template:`
                You are a Lyrical master agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                All of the output must be written in the style of William Shakespeare.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        yoda: {
            label: 'Yoda',
            template:`
                You are a Jedi master agent that does not follow branding policies.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                The all of the output must be written in the style of Yoda.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 1
        },
        email: {
            label: 'External Email',
            template:`
                You are an email marketing agent.
                -----
                Extract the relevant topics / subjects from the command below and use the Knowledge Base tool for relevant information needed to execute the command.
                If there are multiple topics / subjects in the command, use the Knowledge Base tool with each topic / subject separately.
                Do not refer to the tool names either, if the tool is useful then execute it and use the output.
                -----
                Follow all communication policies and branding guidelines.
                The output is an email including today's date and time.
                -----
                Command:
                {INPUT}`,
            model: gptModel,
            temperature: 0
        }
    };
  
    useEffect(scrollToBottom, [messages]);
    
    useEffect(() => {
        if(!MessageTree[parent.context])
            MessageTree[parent.context] = [];

        setMessages(MessageTree[parent.context]);
    }, [parent.context, thread]);

    useEffect(() => {
        
        axios.get(
            `${azure_function_url}/LLMModel?command=history&context=${parent.context}&thread=${thread}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                let mess: Message[] = []
                if(res.data.forEach)
                    res.data.forEach((entry: any) => {
                        const data =  JSON.parse(entry.response);
                        mess.push({ 
                            id: entry.id,
                            author: 'User', 
                            input: entry.input,
                            content: entry.input,
                            timestamp: new Date(entry.prompt_timestamp),
                            data: undefined,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature
                        }, { 
                            id: entry.id,
                            author: 'Skynet', 
                            content: data.text ? data.text : 'error',
                            timestamp: new Date(entry.response_timestamp),
                            data: data,//'error',
                            input: entry.input,
                            tags: entry.metadata,
                            docsearch: entry.docsearch,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature,
                            prompt_tokens: entry.prompt_tokens,
                            completion_tokens: entry.completion_tokens,
                            signature: entry.signature,
                        });
                    });

                setMessages(mess);
                
        }).catch(err => {console.log(err);message.error(err.toString())});

        axios.get(
            `${azure_function_url}/LLMModel?command=tags&context=${parent.context}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                if(res.data)
                    setTagTree(res.data)
        }).catch(err => {console.log(err);message.error(err.toString())});

    }, [parent.context, thread]);

    useEffect(() => {loadThreadList()},[parent.context]);
    useEffect(() => {loadThreadList(false)},[parent.context, thread]);

    useEffect(() => {
        const timer = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timer);
    }, [messages]);

    const handleMessageSend = async () => {
        if (input) {
            // MessageTree[parent.context] = [...messages, { id: '', author: 'User', content: input, input: input, data: undefined, timestamp: new Date(), show: false, agent_reference: '', model: '', temperature: 0 }];
            MessageTree[parent.context] = [
                ...messages, 
                { id: '', author: 'User', content: input, input: input, data: undefined, timestamp: new Date(), show: false, agent_reference: '', model: '', temperature: 0 },
                { id: '', author: 'Skynet', content: '', data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined, prompt_tokens: 0, completion_tokens: 0 }
            ];
            setLoadingChat({ state: true});
            
            setMessages(MessageTree[parent.context]);
            
            for(var i = 0; i < 1; i++){
                try{
                    await axios.post(
                        `${azure_function_url}/LLMModel?command=prompt&context=${parent.context}&thread=${thread}`,
                        {
                            template: prompts[agent].template,
                            input: input,
                            tags: tags ? (tags as any).map((path:any) => path.split('/').pop()) : undefined,
                            model: prompts[agent].model,
                            temperature: prompts[agent].temperature,
                            agent_reference: agent
                        },
                        {
                            headers: {
                                'Authorization': parent.bearerToken
                            },
                            // timeout: 100000, // Set a timeout of 10 seconds
                        }
                    ).then(res => {
                        const date = new Date();
                        // MessageTree[parent.context] = [
                        //     ...messages, 
                        //     { id: res.data.id, author: 'User', content: input, input: input, data: undefined, timestamp: date, show: false, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature }, 
                        //     { id: res.data.id, author: 'Skynet', content: res.data.text, data: res.data, input: input, timestamp: date, show: false, tags: res.data.metadata, docsearch: res.data.docsearch, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature, signature: res.data.signature, prompt_tokens: res.data.prompt_tokens, completion_tokens: res.data.completion_tokens }];
        
                        // setMessages(MessageTree[parent.context]);
                        let index = 0;
                        let prevText = '';
                        const timer = setInterval(() => {
                            if (index < res.data.text.length) {

                                prevText += res.data.text[index];

                                MessageTree[parent.context] = [
                                    ...messages, 
                                    { id: res.data.id, author: 'User', content: input, input: input, data: undefined, timestamp: date, show: false, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature }, 
                                    { id: res.data.id, author: 'Skynet', content: prevText, data: res.data, input: input, timestamp: date, show: false, tags: res.data.metadata, docsearch: res.data.docsearch, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature, signature: res.data.signature, prompt_tokens: res.data.prompt_tokens, completion_tokens: res.data.completion_tokens }];

                                setMessages(MessageTree[parent.context]);
                                index++;
                            } else {
                            clearInterval(timer);
                            }
                        }, 10); // Adjust speed of typing here
                        setLoadingChat({ state: false});
                        forceUpdate();
                    }).catch(err => {
                        console.log(err);
                        message.error(err.toString());

                        const date = new Date();
                        MessageTree[parent.context] = [
                            ...messages, 
                            { id: '', author: 'User', content: input, data: undefined, timestamp: date, input: input, show: false, agent_reference: '', model: '', temperature: 0 }, 
                            { id: '', author: 'Skynet', content: err.toString(), data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }];
        
                        setMessages(MessageTree[parent.context]);
                        setLoadingChat({ state: false});
                        forceUpdate();
                    });
        
                    setInput('');   
                    return;
                }
                catch(err){
                    console.log('---err---', err)
                }
                message.warning(`resending your message. (${i+1}/5)`);
                await new Promise(f => setTimeout(f, 1000));
                setLoadingChat({ state: false});
            }
        }
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        const textarea = event.currentTarget;

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevent creating a new line
            handleMessageSend();
        }
        else if (event.key === 'Tab') {
            event.preventDefault();

            const spaces = "    "; // 4 spaces for a tab, you can adjust this
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = textarea.value.substring(start, end);

            if (start !== end) {
                // Text is selected. Indent each line of the selected text.
                const indentedText = selectedText.split('\n').map(line => spaces + line).join('\n');
                textarea.value = textarea.value.substring(0, start)
                    + indentedText
                    + textarea.value.substring(end);
                
                // Adjust the selection to highlight the newly indented text
                textarea.selectionStart = start;
                textarea.selectionEnd = start + indentedText.length;
            } else {
                // No text selected. Insert spaces for a tab.
                textarea.value = textarea.value.substring(0, start)
                    + spaces
                    + textarea.value.substring(end);

                // Position the caret after the inserted spaces
                textarea.selectionStart = textarea.selectionEnd = start + spaces.length;
            }
        }
    };

    
    let items = [
        {
            label: (
                <span>
                    <MessageOutlined />
                    Chat
                </span>
                ),
            key: '1',
            children: 
                <div ref={listContainerRef}>
                <List
                    style={{ 
                        width: isMobile ? '100%' : '75%',
                        margin: '0 auto'
                    }}
                    itemLayout="vertical"
                    dataSource={messages.map((x,i)=>{return {...x, key:i}})}
                    renderItem={(_message, i) => (
                        <>
                            <List.Item 
                                
                                style={
                                    i === messages.length - 1 ?
                                    { display: 'flex', flexDirection: 'column-reverse' }
                                    :
                                    { display: 'flex', flexDirection: 'column-reverse', borderBottom: '1px solid black' }
                                }

                                actions={
                                    _message.author == 'User' ? 
                                        [
                                            <Popconfirm
                                                title="Delete Message"
                                                description="Would you like to delete the message?"
                                                onConfirm={() => { 
                                                    axios.get(
                                                        `${azure_function_url}/LLMModel?command=history_delete&context=${parent.context}&thread=${thread}&id=${_message.id}`, 
                                                        {
                                                            headers: {
                                                            'Authorization': parent.bearerToken
                                                            }
                                                        }).then(res => {
                                                            let mess: Message[] = []
                                                            if(res.data.forEach)
                                                                res.data.forEach((entry: any) => {
                                                                    const data =  JSON.parse(entry.response);
                                                                    mess.push({ 
                                                                        id: entry.id,
                                                                        author: 'User', 
                                                                        content: entry.input,
                                                                        timestamp: new Date(entry.prompt_timestamp),
                                                                        data: undefined,
                                                                        input: entry.input,
                                                                        show: false,
                                                                        agent_reference: entry.agent_reference,
                                                                        model: entry.model,
                                                                        temperature: entry.temperature
                                                                    }, { 
                                                                        id: entry.id,
                                                                        author: 'Skynet', 
                                                                        content: data.text ? data.text : data,
                                                                        timestamp: new Date(entry.response_timestamp),
                                                                        data: data,
                                                                        tags: entry.metadata,
                                                                        input: entry.input,
                                                                        docsearch: entry.docsearch,
                                                                        show: false,
                                                                        prompt_tokens: entry.prompt_tokens,
                                                                        completion_tokens: entry.completion_tokens,
                                                                        agent_reference: entry.agent_reference,
                                                                        model: entry.model,
                                                                        temperature: entry.temperature,
                                                                        signature: entry.signature
                                                                    });
                                                                })
                                                            
                                                            message.success('Message deleted');
                                                            setMessages(mess);
                                                    }).catch(err => {console.log(err);message.error(err.toString())});
                                                    }
                                                } 
                                                onCancel={() => { }}
                                                okText="Delete"
                                                cancelText="Cancel"
                                            >
                                                <IconText 
                                                    icon={DeleteOutlined} 
                                                />
                                            </Popconfirm>
                                            ,
                                            <IconText 
                                                icon={CopyOutlined} 
                                                text={'Copy'} 
                                                onClick={() => { 
                                                            navigator.clipboard.writeText(_message.content);
                                                            message.success(`Copied!`);
                                                    }
                                                } 
                                            />
                                        ]
                                        : [
                                            <Popconfirm
                                                title="Microsignature"
                                                description="Would you like to microsign this message?"
                                                onConfirm={() => { 
                                                    console.log(_message)
                                                        axios.post(
                                                            `${azure_function_url}/NCID?command=signature_request`,
                                                            {
                                                                document: {
                                                                    id: _message.id,
                                                                    version: 1,
                                                                    type: 'sike_microsignature',

                                                                    model: _message.model,
                                                                    tags: _message.tags,
                                                                    temperature: _message.temperature,
                                                                    agent_reference: _message.agent_reference,
                                                                    sources:  _message.data ? uniqueObjects(_message.data.sourceDocuments.map((x:any)=> { return {name: x?.metadata?.name, type: x?.metadata?.type, id: x?.metadata?.id }}).flat()) : undefined
                                                                },
                                                            },
                                                            {
                                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                                headers: {
                                                                    'Authorization': parent.bearerToken
                                                                }
                                                            }
                                                        ).then(x => { 
                                                            console.log(x)
                                                            
                                                            axios.post(
                                                                `${azure_function_url}/LLMModel?command=add_signature&context=${parent.context}&thread=${thread}&id=${_message.id}`,
                                                                {
                                                                    signature: x.data
                                                                },
                                                                {
                                                                    headers: {
                                                                        'Authorization': parent.bearerToken
                                                                    },
                                                                    
                                                                }
                                                            ).then(res => {
                                                                console.log(res)
                                                                let mess: Message[] = []
                                                                if(res.data.forEach)
                                                                    res.data.forEach((entry: any) => {
                                                                        const data =  JSON.parse(entry.response);
                                                                        mess.push({ 
                                                                            id: entry.id,
                                                                            author: 'User', 
                                                                            content: entry.input,
                                                                            input: entry.input,
                                                                            timestamp: new Date(entry.prompt_timestamp),
                                                                            data: undefined,
                                                                            show: false,
                                                                            agent_reference: entry.agent_reference,
                                                                            model: entry.model,
                                                                            temperature: entry.temperature
                                                                        }, { 
                                                                            id: entry.id,
                                                                            author: 'Skynet', 
                                                                            content: data.text ? data.text : data,
                                                                            timestamp: new Date(entry.response_timestamp),
                                                                            data: data,
                                                                            input: input,
                                                                            tags: entry.metadata,
                                                                            docsearch: entry.docsearch,
                                                                            show: false,
                                                                            prompt_tokens: entry.prompt_tokens,
                                                                            completion_tokens: entry.completion_tokens,
                                                                            agent_reference: entry.agent_reference,
                                                                            model: entry.model,
                                                                            temperature: entry.temperature,
                                                                            signature: entry.signature
                                                                        });
                                                                    })
                                                                
                                                                setMessages(mess);
                                                                message.success('successfully signed');
                                                            });
                                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                                    }
                                                } 
                                                onCancel={() => { }}
                                                okText="Sign"
                                                cancelText="Cancel"
                                            >
                                                <IconText 
                                                    icon={SafetyCertificateOutlined}
                                                    color={_message.signature ? '#52c41a' : undefined} 
                                                    text={'Microsign'}
                                                />
                                            </Popconfirm>,
                                            <IconText 
                                                icon={RedoOutlined} 
                                                text={'Regenerate'} 
                                                onClick={() => { 
                                                        MessageTree[parent.context] = [...messages, { id: '', author: 'User', content: _message.input, input: _message.input, data: undefined, timestamp: new Date(), show: false, agent_reference: '', model: '', temperature: 0 }];
                                                        setMessages(MessageTree[parent.context]);
                                                        setLoadingChat({ state: true});
                                                                        

                                                        axios.post(
                                                            `${azure_function_url}/LLMModel?command=prompt&context=${parent.context}&thread=${thread}`,
                                                            {
                                                                template: prompts[agent].template,
                                                                input: _message.input,
                                                                tags: tags ? (tags as any).map((path:any) => path.split('/').pop()) : undefined,
                                                                model: prompts[agent].model,
                                                                temperature: prompts[agent].temperature,
                                                                agent_reference: agent
                                                            },
                                                            {
                                                                headers: {
                                                                    'Authorization': parent.bearerToken
                                                                },
                                                                // timeout: 100000, // Set a timeout of 10 seconds
                                                            }
                                                        ).then(res => {
                                                            const date = new Date();
                                                            // MessageTree[parent.context] = [
                                                            //     ...messages, 
                                                            //     { id: res.data.id, author: 'User', content: input, input: input, data: undefined, timestamp: date, show: false, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature }, 
                                                            //     { id: res.data.id, author: 'Skynet', content: res.data.text, data: res.data, input: input, timestamp: date, show: false, tags: res.data.metadata, docsearch: res.data.docsearch, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature, signature: res.data.signature, prompt_tokens: res.data.prompt_tokens, completion_tokens: res.data.completion_tokens }];
                                            
                                                            // setMessages(MessageTree[parent.context]);
                                                            let index = 0;
                                                            let prevText = '';
                                                            const timer = setInterval(() => {
                                                                if (index < res.data.text.length) {

                                                                    prevText += res.data.text[index];

                                                                    MessageTree[parent.context] = [
                                                                        ...messages, 
                                                                        { id: res.data.id, author: 'User', content: input, input: input, data: undefined, timestamp: date, show: false, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature }, 
                                                                        { id: res.data.id, author: 'Skynet', content: prevText, data: res.data, input: input, timestamp: date, show: false, tags: res.data.metadata, docsearch: res.data.docsearch, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature, signature: res.data.signature, prompt_tokens: res.data.prompt_tokens, completion_tokens: res.data.completion_tokens }];

                                                                    setMessages(MessageTree[parent.context]);
                                                                    index++;
                                                                } else {
                                                                clearInterval(timer);
                                                                }
                                                            }, 10); // Adjust speed of typing here
                                                            setLoadingChat({ state: false});
                                                            forceUpdate();
                                                        }).catch(err => {
                                                            console.log(err);
                                                            message.error(err.toString());
                                    
                                                            const date = new Date();
                                                            MessageTree[parent.context] = [
                                                                ...messages, 
                                                                { id: '', author: 'User', content: input, data: undefined, input: input, timestamp: date, show: false, agent_reference: '', model: '', temperature: 0 }, 
                                                                { id: '', author: 'Skynet', content: err.toString(), data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }];
                                            
                                                            setMessages(MessageTree[parent.context]);
                                                            setLoadingChat({ state: false});
                                                            forceUpdate();
                                                        });
                                                    }
                                                } 
                                            />,
                                            <IconText 
                                                icon={CopyOutlined} 
                                                text={'Copy'} 
                                                onClick={() => { 
                                                            navigator.clipboard.writeText(_message.content);
                                                            message.success(`Copied!`);
                                                    }
                                                } 
                                            />
                                        ]
                                    }

                            >
                                <div style={{ flexGrow: 1 }}>
                                    <List.Item.Meta 
                                        avatar={ _message.author == 'User' ? <Avatar src={parent.user.avatar} /> : <Avatar src="sike_logo.png" /> }
                                        title={
                                            <span style={{ fontSize: '20px' }}>
                                                {_message.author == 'User' ? parent.user.data.personal.name.firstName : prompts[_message.agent_reference] ? 'SIKE | ' + prompts[_message.agent_reference].label : 'SIKE'}
                                            </span>
                                        }
                                        description={`${_message.timestamp.toLocaleString()}` + (_message.author !== 'User' ? ` | Tokens = AUD ${_message.prompt_tokens && _message.completion_tokens ? (_message.prompt_tokens * prompt_cost + _message.completion_tokens * completion_cost).toFixed(2) : 0}` : '')} 
                                    />
                                    
                                    {/* <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(_message.content) }} /> */}
                                    {
                                        _message.content !== '' ?
                                    
                                        <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(_message.content) }} />
                                    :
                                        <>
                                        <Spin tip="Loading...">
                                            <Alert
                                                message="Working on it..."
                                                description="I need a second to work my magic!"
                                                type="info"
                                            />
                                        </Spin>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        </>
                                    }
                                    <br></br>
                                    { 
                                            _message.data?.sourceDocuments?.length > 0 ?
                                                <>
                                                <Space size={[0, 8]} >
                                                    { 
                                                        Array.from(new Set(_message.data.sourceDocuments.map((x:any)=>x.metadata.tags).flat())).map((x:any)=><CheckableTag style={_message.tags?.includes(x) ? undefined : { backgroundColor: '#f0f0f0', borderColor: '#d9d9d9', color: '#595959' }}
                                                        key={x} checked={_message.tags?.includes(x)}>{x}</CheckableTag>)
                                                    }
                                                </Space>
                                                <br></br>
                                                <br></br>
                                                </>
                                            :
                                            <></>
                                    }
                                    { 
                                            _message.data && _message.data.sourceDocuments ?
                                            <>
                                                { _message.docsearch ? (isValidJson(_message.docsearch) ? JSON.parse(_message.docsearch).map((x:string, i:number)=> <Tag key={i} icon={<SearchOutlined />}>{x}</Tag>) : <Tag icon={<SearchOutlined />}>{_message.docsearch}</Tag>) : <></>}
                                                <br></br>
                                                <br></br>
                                                <Space size={[0, 8]} wrap>
                                                    { 
                                                        uniqueObjects(_message.data.sourceDocuments?.map((x:any)=> { return {name: x?.metadata?.name, type: x?.metadata?.type, id: x?.metadata?.id }}).flat())
                                                        .filter((x:any)=>x.name)
                                                        .map((x:any, i:number)=>
                                                            <a
                                                                key={i}
                                                                onClick={async () => {
                                                                    try{
                                                                    const name = x.name+"."+x.type;
                                                                    const { data } = await axios.get(
                                                                        `${azure_function_url}/LLMModel?command=vectorised_files_get&id=${x.id}`,
                                                                        {
                                                                            headers: {
                                                                            'Authorization': parent.bearerToken
                                                                        }})

                                                                    const file = new File([Buffer.from(data.base64,"base64")], name, {type: 'application/octet-stream'});
                                                                    const element = document.createElement("a");
                                                                    element.href = URL.createObjectURL(file);
                                                                    element.download = name;
                                                                    document.body.appendChild(element);
                                                                    element.click();

                                                                    }catch(err:any){{console.log(err);message.error(err.toString())}};
                                                                }}
                                                            >
                                                                <Tag icon={x.type === "pdf" ? <FilePdfOutlined />:<FileWordOutlined />} color="success">
                                                                    {x.name}.{x.type}
                                                                </Tag>
                                                            </a>
                                                        )
                                                    }
                                                </Space>
                                                <br></br>
                                                <br></br>
                                            </>
                                            :
                                            <></>
                                    }
                                    
                                </div>    
                            </List.Item>
                                
                        </>
                    )}
                />
            
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
                <div style={{ 
                    display: 'flex', 
                    width: '70%', 
                    margin: '0 auto', 
                    backgroundColor: 'white',
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.75)', 
                    position: 'fixed',
                    bottom: 50,
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}>
                    <Form initialValues={{agent: agent}} style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '10px' }}>
                        <Button 

                            type="primary" 
                            icon={(isLoadingChat.state ? <LoadingOutlined />:<></>)} 
                            style={{ 
                                color: 'rgba(0, 0, 0, 0.75)',
                                backgroundColor: 'transparent', 
                                borderColor: 'transparent', 
                                borderRadius: '0 0 0 0',
                                alignSelf: 'flex-end',
                                marginBottom: '0px',
                                boxShadow:'none'
                            }}
                        />
                        
                        
                        
                        <Item style={{ flexGrow: 1, marginBottom: 0, marginRight: '-1px' }}>
                            <TextArea 
                                ref={messageInputRef}

                                disabled={isLoadingChat.state}
                                placeholder="How can I help you?" 
                                value={input} 
                                onChange={e => setInput(e.target.value)} 
                                onKeyDown={handleKeyDown}
                                autoSize={{ minRows: 1 }}
                                bordered={false}
                            />
                            
                        </Item>
                        
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '30%' }}>
                            <Space.Compact 
                                style={{ 
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexGrow: 1,
                                    marginBottom: 0,
                                    marginRight: '-1px',
                                }}
                            >
                                <Item name={'agent'} noStyle>
                                    <Select 
                                        style={{ 
                                            width: '100%'
                                        }} 
                                        onChange={(value) => { setAgent(value) }}  placeholder="Select persona">
                                        { Object.keys(prompts).map((key, i) => <Option key={i} value={key}>{prompts[key].label}</Option> )}
                                    </Select>                                
                                </Item>
                                <Item name={'tags'} noStyle>
                                    <TreeSelect
                                        style={{ 
                                            width: '100%',
                                        }} 
                                        dropdownStyle={{ 
                                            minWidth: '450px', // Ensure that the dropdown is at least as wide as the input
                                            maxWidth: '50%', // Example to ensure it doesn't exceed the viewport width, adjust as necessary
                                            // overflow: 'auto'  // This ensures that if the content overflows it will be scrollable
                                        }}
                                        placeholder={'Select tags...'}
                                        multiple={true}
                                        onChange={e => setTags(e)} 
                                        treeData={tagTree}
                                    />  
                                </Item>
                                
                            </Space.Compact>
                            <Button
                                    type="primary" 
                                    icon={<SendOutlined />} 
                                    onClick={handleMessageSend}
                                    style={{ 
                                        color: 'rgba(0, 0, 0, 0.75)',
                                        backgroundColor: 'transparent', 
                                    }}
                                />
                        </div>
                    </Form>
                </div>
                <div ref={messagesEndRef} />

                <FloatButton.Group
                    type="default"
                    style={{ right: 25, bottom: 75 }}
                >
                    {parent.permission !== 'WORKER' ? <FloatButton tooltip={<div>Permissions</div>} icon={<TeamOutlined />} onClick={()=> {setActiveTabKey('2')}} /> : <></>}
                    <Popconfirm
                        title="Delete All Messages"
                        description="Would you like to delete all the messages?"
                        onConfirm={() => { 
                            axios.get(
                                `${azure_function_url}/LLMModel?command=history_delete_all&context=${parent.context}&thread=${thread}`, 
                                {
                                    headers: {
                                    'Authorization': parent.bearerToken
                                    }
                                }).then(res => {
                                    let mess: Message[] = []
                                    if(res.data.forEach)
                                        res.data.forEach((entry: any) => {
                                            const data =  JSON.parse(entry.response);
                                            mess.push({ 
                                                id: entry.id,
                                                author: 'User', 
                                                content: entry.input,
                                                input: entry.input,
                                                timestamp: new Date(entry.prompt_timestamp),
                                                data: undefined,
                                                show: false,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature
                                            }, { 
                                                id: entry.id,
                                                author: 'Skynet', 
                                                content: data.text ? data.text : data.output ? data.output : data,
                                                timestamp: new Date(entry.response_timestamp),
                                                data: data,
                                                input: entry.input,
                                                tags: entry.metadata,
                                                docsearch: entry.docsearch,
                                                show: false,
                                                prompt_tokens: entry.prompt_tokens,
                                                completion_tokens: entry.completion_tokens,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature,
                                                signature: entry.signature
                                            });
                                        })
                                    
                                    message.success('Messages deleted');
                                    setMessages(mess);
                            }).catch(err => {console.log(err);message.error(err.toString())});
                            }
                        } 
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <FloatButton tooltip={<div>Clear Chat History</div>} icon={<DeleteOutlined />} onClick={()=> {}} />
                    </Popconfirm>
                    <FloatButton tooltip={<div>Scroll Up</div>} icon={<UpOutlined />} onClick={()=> {scrollToTop();}} />
                    <FloatButton tooltip={<div>Scroll Down</div>} icon={<DownOutlined />} onClick={()=> {scrollToBottom();}} />
                </FloatButton.Group>
                </div>
        }
    ]

    if(parent.permission !== 'WORKER'){
        items = items.concat(
            {
                label: (
                    <span>
                        <TeamOutlined />
                        Permissions
                    </span>
                    ),
                key: '2',
                children: 
                    <><Employees user={parent.user} bearerToken={parent.bearerToken} entityId={parent.context} /></>
            });
    }
        
       
    return (
        <>
            <div ref={messagesTopRef} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){// && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        width: 275, 
                        borderRadius:'9px', 
                        marginRight:'10px',

                        position: 'sticky',
                        paddingTop: '25px',
                        top: '70px', // Adjust the value as needed to position the menu correctly
                        left: '20px', // Adjust the value as needed to position the menu correctly
                        height: 'calc(100vh - 70px)', // Adjust the value as needed
                        overflowY: 'auto' 
                    }}
                    // items={_items}
                    items={menuItems}
                />
                <Card 
                    bordered={false} 
                    style={{
                        width: isMobile ? '100%' : '75%',
                    }}
                >
                    
                    <Tabs
                        activeKey={activeTabKey}
                        defaultActiveKey="1"
                        onChange={(key) => setActiveTabKey(key)}
                        type="card"
                        size="large"
                        
                        items={items} 
                    />

                </Card>
            </div>
            

            <Modal okType={'default'} okText={'Close'} cancelButtonProps={{ style: { display: 'none' } }} title="View Sources" width={1000} onCancel={() => setOpenMessages(undefined)} onOk={() => setOpenMessages(undefined)}  closable={true} open={openMessage !== undefined}>
                <Card bordered={true} style={{ width: '100%' }}>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(openMessage ? openMessage.content.toString() : '') }} />

                    {openMessage?.data.sourceDocuments.length > 0 ?
                    <>
                    <Divider orientation="left" plain>Sources</Divider>
                    <List
                        style={{ 
                            width: '100%', 
                            margin: '0 auto'
                        }}
                        itemLayout="vertical"
                        dataSource={openMessage?.data.sourceDocuments.map((x:any,i:number)=>{return {...x, key:i}})}
                        renderItem={(_message:any) => 
                            <List.Item>
                                
                                <div style={{ paddingLeft: '40px', paddingRight: '40px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(_message.pageContent) }} />
                                <br></br>
                                <br></br>
                                <JsonView collapsed={1} src={_message.metadata} />    
                            </List.Item>
                        }
                    />
                    </>
                    :<></>
                    }
                    
                </Card>
            </Modal>
        </>
    );
}