import React, { useState, useEffect } from 'react';
import { Divider, List, Card, Button, message } from 'antd';

import { UndoOutlined, CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

// import { SmartUI } from '../SmartUI/SmartUI';

import { ViewDocument } from './ViewDocument';

import { FrankieOneDocumentTypes } from '../../utils/constants';
import { FrankieOneDocument, UserUI, UserData } from '../../utils/data_types';

import axios from 'axios';

export interface IVerifiedDocumentsProps {
    pt100: boolean;
    user?: UserUI;
    data?: UserData | null;
    bearerToken: string;
    doc: { name: string, type: FrankieOneDocumentTypes | string, doc: FrankieOneDocument } | undefined;
    update: () => void;
}
export const VerifiedDocuments: React.FC<IVerifiedDocumentsProps> = (parent) => {
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const [smartUIDoc, setSmartUIDoc] = useState<any>(undefined);
    const [viewDoc, setViewDoc] = useState<{name: string, type: FrankieOneDocumentTypes | string, doc: FrankieOneDocument | undefined } | undefined>(undefined);


    const updateBackEnd = () => axios.get(
        `${azure_function_url}/Databoss?command=kyc_update`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then(() => {
        parent.update();
        setSmartUIDoc(undefined); 
    });

    useEffect(() => { 
        parent.update();
    }, []);

    return (
        <>
            <Card 
                bordered={false} 
            >
                {
                parent.user ?
                <>  
                    <List itemLayout="horizontal">    
                        <List.Item key={0}>
                            <List.Item.Meta
                                avatar={Object.keys(parent.data?.passport || []).length === 0 ? <InfoCircleOutlined style={{ fontSize: '25px', color: '#E49B0F', marginTop:'10px' }}/> : <CheckCircleOutlined style={{ fontSize: '25px', color: '#52c41a', marginTop:'10px' }} />}
                                title={
                                    <a 
                                        onClick={async () => { 
                                            if(Object.keys(parent.data?.passport || []).length === 0){
                                                setSmartUIDoc(FrankieOneDocumentTypes.PASSPORT);
                                            }
                                            else{
                                                if(parent.data?.passport){
                                                    setViewDoc({ name: 'Passport', type: FrankieOneDocumentTypes.PASSPORT, doc: parent.data?.passport });
                                                    setSmartUIDoc(undefined);
                                                }
                                            }
                                    }}>
                                        Passport
                                    </a>
                                }
                                description={parent.data?.passport?.idNumber ? `Number: ${parent.data?.passport?.idNumber}` : undefined}
                            /> 
                        </List.Item>
                        <List.Item key={1}>
                            <List.Item.Meta
                                avatar={Object.keys(parent.data?.license || []).length === 0 ? <InfoCircleOutlined style={{ fontSize: '25px', color: '#E49B0F', marginTop:'10px' }}/> : <CheckCircleOutlined style={{ fontSize: '25px', color: '#52c41a', marginTop:'10px' }} />}
                                title={
                                    <a 
                                        onClick={async () => { 
                                            if(Object.keys(parent.data?.license || []).length === 0){
                                                setSmartUIDoc(FrankieOneDocumentTypes.DRIVERS_LICENCE);
                                            }
                                            else{
                                                if(parent.data?.license){
                                                    setViewDoc({ name: 'License', type: FrankieOneDocumentTypes.DRIVERS_LICENCE, doc: parent.data?.license });
                                                    setSmartUIDoc(undefined);
                                                }

                                            }
                                    }}>
                                        Driver's License
                                    </a>
                                }
                                description={parent.data?.license?.idNumber ? `Number: ${parent.data?.license?.idNumber}` : undefined}
                            /> 
                        </List.Item>
                        {/* <List.Item key={2}>
                            <List.Item.Meta
                                avatar={Object.keys(user.national_id).length === 0 ? <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/> : <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />}
                                title={
                                    <a onClick={async () => { 
                                        console.log('national id', user.national_id) 
                                        if(Object.keys(user.national_id).length === 0){
                                            setSmartUIDoc('NATIONAL_ID');
                                        }
                                        else{
                                            setViewDoc({ name: 'National ID', type: 'NATIONAL_ID', doc: user.national_id });
                                            setSmartUIDoc(undefined);
                                        }
                                    }}>
                                        National ID
                                    </a>
                                }
                                description={user.national_id.idNumber ? `Number: ${user.national_id.idNumber}` : undefined}
                            /> 
                        </List.Item> */}
                        <List.Item key={3}>
                            <List.Item.Meta
                                avatar={Object.keys(parent.data?.medicare || []).length === 0 ? <InfoCircleOutlined style={{ fontSize: '25px', color: '#E49B0F', marginTop:'10px' }}/> : <CheckCircleOutlined style={{ fontSize: '25px', color: '#52c41a', marginTop:'10px' }} />}
                                title={
                                    <a 
                                        onClick={async () => { 
                                            if(Object.keys(parent.data?.medicare || []).length === 0){
                                                setSmartUIDoc(FrankieOneDocumentTypes.NATIONAL_HEALTH_ID);
                                            }
                                            else{
                                                if(parent.data?.medicare){
                                                    setViewDoc({ name: 'Medicare Card', type: FrankieOneDocumentTypes.NATIONAL_HEALTH_ID, doc: parent.data?.medicare });
                                                    setSmartUIDoc(undefined);
                                                }
                                            }
                                    }}>
                                        Medicare Card
                                    </a>
                                }
                                description={parent.data?.medicare?.idNumber ? `Number: ${parent.data?.medicare?.idNumber}` : undefined}
                            /> 
                        </List.Item>
                    </List>

                    
                </>
                :
                <></>
                }

            
            </Card>

            {
                smartUIDoc ?
                <>
                <br></br>
                <Card 
                    extra={
                        <Button icon={<CloseCircleOutlined/>} onClick={ () => setSmartUIDoc(undefined) } style={{float: 'right', border:'none'}}></Button>
                    }
                >
                    {/* <SmartUI bearerToken={parent.bearerToken} done={ () => { updateBackEnd(); setViewDoc(undefined) }} smartUIDoc={smartUIDoc} /> */}
                </Card>
                </>
                :
                viewDoc ?
                <>
                    <ViewDocument done={() => setViewDoc(undefined) } user={parent.user} bearerToken={parent.bearerToken} doc={viewDoc}></ViewDocument>
                </>
                :
                <></>
            }
        </>
    )
}