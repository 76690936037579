import { Space, Spin, Layout, Image } from 'antd';
import { logoPath } from '../../utils/config';

export const Loading = () => {
    const { Content } = Layout;
    return (
        <Layout>
            <Content 
                style={{ 
                    backgroundColor: '#00B5C9',
                    padding: '50px 50px', 
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >   
                <Image
                    preview={false}
                    height={90}
                    width={90 * 226 / 90}
                    src={logoPath}
                />
                <br></br>
                <br></br>
                <Space size="middle">
                    <Spin 
                        size="large" 
                        style={{color:'white'}}
                    />
                </Space>
            </Content>
        </Layout>
    )
}