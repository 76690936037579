export const gptModel = 'gpt-4';

export const getPrompt = (context: string, thread: string, agent: string, input: string, tags?: string[]) => {
    if(agent === 'GPT4'){
        return {
            json: [
                { "history": 5 },
                { "context": context },
                { "thread": thread },
                { "model": gptModel },
                {
                    "prompt": "first",
//                     "rag": [
//                         {
//                             "module": context,
//                             "description": 
//                                 `
// 1. ${context} is a RAG tool.
// 2. Use the RAG tool to search for any topic or context.
// 3. If there are more than one subject, create several queries, one query per subject.
// 4. For example if the query is about A and/or B then first ask about A then B.
//                                 `,
//                             "tags": tags ? (tags as any).map((path:any) => path.split('/').pop()) : undefined
//                         }
//                     ],
                    "temperature": prompts[agent].temperature,
                    "instructions": [`${input}`]
                }
                // { "prompt": "last", "action": "Format", "format": "Markdown" }
            ],
            input: input,
            agent_reference: agent
        }
    }
    else if(agent === 'chat'){
        return {
            json: [
                { "history": 5 },
                { "context": context },
                { "thread": thread },
                { "model": gptModel },
                {
                    "prompt": "first",
                    "rag": [
                        {
                            "module": context,
                            "description": 
                                `
1. ${context} is a RAG tool.
2. Use the RAG tool to search for any topic or context.
3. If there are more than one subject, create several queries, one query per subject.
4. For example if the query is about A and/or B then first ask about A then B.
                                `,
                            "tags": tags ? (tags as any).map((path:any) => path.split('/').pop()) : undefined
                        }
                    ],
                    "temperature": prompts[agent].temperature,
                    "instructions": 
                        [
                            `
You are an assistant with access to a set of tools in the form of functions and databases.
Extract the relevant topics / subjects / command from the command below and use the tools for relevant information or to execute the command.
If you don't find a topic or context being specified in the command then search for \"general descriptions\" in EVERY SINGLE one of the relevant database tools separately.
Make sure you use every single database tool when the topic is general.
Use multiple tools when relevant.
If there are multiple topics / subjects in the command, use the tools with each topic / subject separately.
ONLY respond based off information available in the tools or the chat history.
If the statement requires information that is NOT in the database tools or the chat history say you don't know.
ONLY respond in simple markdown text.
Check that the response only pertains to information found in the tools.
---
${input}`
                        ]
                },
                // { "prompt": "last", "action": "Format", "format": "Markdown" }
            ],
            input: input,
            agent_reference: agent
        }
    }
    else if(agent === 'hybrid'){
        return {
            json: [
                { "history": 5 },
                { "context": context },
                { "thread": thread },
                { "model": gptModel },
                {
                    "prompt": "first",
                    "rag": [
                        {
                            "module": context,
                            "description": 
                                `
1. ${context} is a RAG tool.
2. Use the RAG tool to search for any topic or context.
3. If there are more than one subject, create several queries, one query per subject.
4. For example if the query is about A and/or B then first ask about A then B.
                                `,
                            "tags": tags ? (tags as any).map((path:any) => path.split('/').pop()) : undefined
                        }
                    ],
                    "temperature": prompts[agent].temperature,
                    "instructions": 
                        [
                            `
Extract the relevant topics / subjects from the command below and use the tools for relevant information needed to execute the command.
If you don't find a topic or context being specified in the command then search for \"general descriptions\" in EVERY SINGLE one of the relevant RAG tools separately.
Make sure you use every single RAG tool when the topic is general.
Use multiple tools when relevant.
If there are multiple topics / subjects in the command, use the tools with each topic / subject separately.
ONLY answer based off information available in the tools or the chat history.
If the answer is NOT in the RAG tool then write \"<span style='color:red'>**WARNING: Source is not in the provided documents!**</span>\" and then respond with relevant information.
DO NOT refer to the tools name in the response, if the tools is useful then execute it and use the output.
ONLY respond in simple markdown text.
Check that the response only pertains to information found in the tools.
---
${input}`
                        ]
                },
                // { "prompt": "last", "action": "Format", "format": "Markdown" }
            ],
            input: input,
            agent_reference: agent
        }
    }
    
    
}

export const prompts: Record<string, any> = {
    GPT4: {
        label: 'GPT-4 Turbo',
        temperature: 0
    },
    chat: {
        label: 'Documents',
        temperature: 0
    },
    hybrid: {
        label: 'Hybrid',
        temperature: 0
    },
    // pirate: {
    //     label: 'Pirate',
    //     template:`
    //         You are a Pirate agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         All of the output must be written in the style of a Pirate.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // gangsta: {
    //     label: 'Gangsta',
    //     template:`
    //         You are a Gangsta Rapper agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         All of the output must be written in the style of a Gangsta Rapper.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // alig: {
    //     label: 'Ali G',
    //     template:`
    //         You are an Ali G agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         All of the output must be written in the offensive style of Ali G.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // borat: {
    //     label: 'Borat',
    //     template:`
    //         You are a Borat agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         All of the output must be written in the offensive style of Borat.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // shakespeare: {
    //     label: 'Shakespeare',
    //     template:`
    //         You are a Lyrical master agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         All of the output must be written in the style of William Shakespeare.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // yoda: {
    //     label: 'Yoda',
    //     template:`
    //         You are a Jedi master agent that does not follow branding policies.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         The all of the output must be written in the style of Yoda.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 1
    // },
    // email: {
    //     label: 'External Email',
    //     template:`
    //         You are an email marketing agent.
    //         -----
    //         Extract the relevant topics / subjects from the command below and use the SIKE-Base tool for relevant information needed to execute the command.
    //         If there are multiple topics / subjects in the command, use the SIKE-Base tool with each topic / subject separately.
    //         Do not refer to the tool names either, if the tool is useful then execute it and use the output.
    //         -----
    //         Follow all communication policies and branding guidelines.
    //         The output is an email including today's date and time.
    //         -----
    //         Command:
    //         {INPUT}`,
    //     model: gptModel,
    //     temperature: 0
    // }
};