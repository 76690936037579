// App.tsx

import React, { useState, useRef, useEffect } from 'react';

import { Col, Row, Result, Select, Spin, Alert, Menu, Popconfirm, Radio, Avatar, TreeSelect, Divider, Modal, Card, Tooltip, FloatButton, Input, Button, List, Form, Upload, Table, DatePicker, Space, Tabs, message } from 'antd';
import type { UploadProps } from 'antd';
import type { MenuProps } from 'antd';

import { ExperimentOutlined, TeamOutlined, SettingOutlined, EditOutlined, QuestionCircleOutlined, RedoOutlined, SafetyCertificateOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, CopyOutlined, BarsOutlined, DownOutlined, UpOutlined, UploadOutlined, SendOutlined, MessageOutlined, LoadingOutlined, CloudDownloadOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, DatabaseOutlined } from '@ant-design/icons';

import { isMobileBrowser } from '../../utils/utils';

import { getData } from 'country-list';

import dayjs from 'dayjs';

import { SIKE } from '../sike/SIKE';

import { Employees } from '../employees/Employees';

import axios from 'axios';

const { Dragger } = Upload;

const { Option } = Select;

const { Column } = Table;

type MenuItem = Required<MenuProps>['items'][number];


interface Message {
    id: string;
    author: string;
    timestamp: Date;
    content: any;
    input: string;
    data: any;
    show: boolean;
    prompt_tokens?: number;
    completion_tokens?: number;
    docsearch?: string;
    tags?: any;
    agent_reference: string;
    model: string;
    temperature: number;
    signature?: any;
}

const MessageTree : Record<string,  Message[]> = {}

const InputLabel: React.FC<any>  = (parent: any) => {
    
    return (
        <div 
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                width:'100%',                
                height:'70px',
                borderRadius: '7px',
                paddingLeft: parent.selectedThread === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                borderLeft: parent.selectedThread === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                backgroundColor: parent.selectedThread === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
            }}
        >
            {parent.done ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '16px' }} />:<QuestionCircleOutlined style={{ fontSize: '16px' }} />}
            <a 
                style={{ 
                    flexGrow: 1, 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    paddingLeft: '10px',
                    fontSize: '18px'
                }}
                onClick={(e)=>{                    
                    parent.setThread(parent.keyVal);
                    parent.scrollToId(parent.keyVal);
                }}
            >
                {parent.label}
            </a>
        </div>
    );
};

export const Questionnaire: React.FC<any> = (parent) => {

    const [fileList, setFileList] = useState<any[]>();
    const [isLoadingFiles, setLoadingFiles] = useState({ state: true});
    
    const [thread, setThread] = useState('2023-12-12');
    const [version, setVersion] = useState('2023-12-12');
    const [counter, setCounter] = useState(0);
    const [processCounter, setProcessCounter] = useState(0);

    const [formData, setFormData] = useState<any>({});
    const [form] = Form.useForm();

    const [context, setContext] = useState('9ac85e2c-4fc0-49e2-b350-6a0c279131f7');
    const [messages, setMessages] = useState<Message[]>([]);
    
    const listContainerRef = useRef<HTMLDivElement | null>(null);

    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const [openKeys, setOpenKeys] = useState(['1']);

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [ghgTarget, setGhgTarget] = useState('');
    const [OrganisationType, setOrganisationType] = useState('');

    const [questions, setQuestions] = useState<any[]>([]);
    const [qHash, setQHash] = useState<any>({});

    const [activeQuestion, setActiveQuestion] = useState('');

    const [viewPermissions, setViewPermissions] = useState(false);

    const [isTimerActive, setIsTimerActive] = useState(false);
    const [lastSaved, setLastSaved] = useState(new Date());
    const [lastChanged, setLastChanged] = useState(new Date());

    const [isMagic, setMagic] = useState(false);

    const [activeTabKey, setActiveTabKey] = useState('1'); // Default active tab key

    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key
    
    
    const uint8ArrayToBase64 = (buffer: Uint8Array): string => {
        let binary = '';
        const len = buffer.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(buffer[i]);
        }
        return window.btoa(binary);
    }

    const fileChange = async (pid: string, id: string, info: any) => { 
        
        const file = info.file;
        if (file.status === 'uploading') {
            
            const buffer = await (file.originFileObj as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const b64 = uint8ArrayToBase64(bytes);

            const extendedFile = {...file, b64: b64};

            file.status = "done";
            file.percent = 100;
            file.response = "Ok";
            
            setCounter((x)=>x+1);
            message.success(`${info.file.name} file uploaded successfully.`);
        }
        else if (file.status === 'removed') {
            formData[pid][id] = info;
            
            message.success(`${info.file.name} file removed successfully.`);
        }
        else if (file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        setCounter((x)=>x+1);
    };

    const props: UploadProps = { 
        multiple: false,
        showUploadList: true,
        accept: '.pdf, .ts, .md',
        customRequest: (options)=> { 
            const { onSuccess, onError, file, onProgress } = options;
            if(onSuccess){
                onSuccess("Ok");
            }
        }
    }

    const hasUndefinedProperty = (obj: Record<string, any>, level: number = 0): boolean => {

        if(obj === undefined){
            return true;
        }

        if(Object.keys(obj).length === 0){
            return true;
        }

        if(level > 1){
            return false;
        }
        
        for (const key in obj) {
            if (!key.startsWith('_') && obj.hasOwnProperty(key)) {
                if (obj[key] === undefined) {
                    return true;
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    // Recursive call for nested objects
                    if (hasUndefinedProperty(obj[key], level + 1)) {
                        return true;
                    }
                }
            }
        }

        // setCounter((x)=>x+1);   

        return false;
    }

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const mergeObjects = (obj1: any, obj2: any) => {
        const result: any = obj1 instanceof Array ? [...obj1] : { ...obj1 };
    
        for (const [key, value] of Object.entries(obj2)) {
            // If both values are objects, merge them recursively
            if (value instanceof Object && key in result && result[key] instanceof Object) {
                
                if(value instanceof Array && result[key] instanceof Array){
                    
                    if(value.filter((x:any)=>x != null).length > 1){
                        result[key] = mergeObjects(result[key], value);
                    }
                    else if(value.filter((x:any)=>x != null).length === 1){
                        const _idx : number = parseInt(Object.keys(value)[0]);
                        result[key][_idx] = mergeObjects(result[key][_idx], value[_idx]);
                    }
                    else if(value.length === 0){
                        result[key] = value;
                    }
                    else{
                        result[key] = mergeObjects(result[key], value);
                    }

                }
                else
                {
                    if(Object.keys(result[key]).length > Object.keys(value).length){
                        result[key] = value;
                    }
                    else {
                        result[key] = mergeObjects(result[key], value);
                    }
                    
                }

            } else {
                // Otherwise, set/overwrite the value from obj2
                if(result[key] instanceof Array){
                    if(value instanceof Array){
                        result[key] = value;
                    }
                    else{
                        result[key].push(value);
                    }
                }
                else{
                    result[key] = value;
                }
            }
        }
        return result;
    }

    const processValueChange = async (id: string, changedValues: Record<string, any>, allValues: Record<string, any>) => {
        if(!(id in formData))
            formData[id] = {};

        formData[id] = mergeObjects(formData[id], changedValues)

        if(!hasUndefinedProperty(allValues)){
            formData[id]['_done'] = true;
        }

        setFormData(formData);
        setProcessCounter((x)=>x+1);
        setCounter((x)=>x+1);
    }

    const saveFunction = (formData:any) => {
        console.log('saving', formData)
        axios.post(
            `${azure_function_url}/Totalis?command=form_upload&context=${context}&version=${version}`,
            { data: formData },
            {
                headers: {
                    'Authorization': parent.bearerToken
                }
        })
        .then(x => { 
            setIsTimerActive(false); // Reset the timer flag
            setLastSaved(new Date());
            setCounter(x=>x+1)
        })
        .catch(x => console.log(x));

        
    };

    // saving
    useEffect(() => {
        if (!isTimerActive && Object.keys(formData).length > 0 && processCounter > 0) {
            setIsTimerActive(true); // Set the timer flag to active
      
            const timer = setTimeout(() => {
                saveFunction(formData);
                clearTimeout(timer);
            }, 2 * 1000);

        }
        setLastChanged(new Date());
        
    }, [processCounter]);

    useEffect(() => {
        if (!isTimerActive && Object.keys(formData).length > 0 && processCounter > 0 && (lastChanged.getTime() - lastSaved.getTime()) / 1000 > 2) {
            const timer = setTimeout(() => {
                saveFunction(formData);
                clearTimeout(timer);
            }, 1 * 1000);
        }
        setLastChanged(new Date());
        
    }, [isTimerActive]);

    const magicWand = (activeQuestion: string) => {
        setMagic(true);
        setCounter((x)=>x+1);
        const questions: Record<string,any> = { 
            'General Questions': [
                // General Questions
                {
                    id: `Organisation Name`,
                    question: 
`only respond with the answer: what is the full name of the organisation in the docs?`
                },
                {
                    id: `Organisation Type`,
                    question:
`which best describes the organisation? only answer with a choice from the following list: 
                            
Public Listed Company
Privately Held Company
Sole Trader 
Trust 
Partnership 
Joint Venture 
                
if you don't know, respond with 'Other'`
                },
                {
                    id: `ACN / ABN`,
                    question:
`only respond with the answer: what is the ABN of the company?`
                },
                {
                    id: `Business Address`,
                    question: 
`only respond with the answer: what is the full company address?`
                },
                {
                    id: `A$ Annual Revenue`,
                    question: 
`only respond with the dollar answer: what is annual revenue of the business?`
                },
                {
                    id: `Number of employees (total workforce incl part-time, casual, contracted)`,
                    question: 
`only respond with the numerical answer: what is the total number of employees?`
                }
            ],
            'Environmental Questions': [
                {
                    id: `Does your organisation measure its direct greenhouse gas (GHG) emissions from controlled or owned sources (i.e., Scope 1 emissions) and indirect GHG emissions from the generation of purchased energy (i.e., Scope 2 emissions)?`,
                    question:
`only respond with "yes" or "no": Does your organisation measure its direct greenhouse gas (GHG) emissions from controlled or owned sources (i.e., Scope 1 emissions) and indirect GHG emissions from the generation of purchased energy (i.e., Scope 2 emissions)?`
                },
                {
                    id: `Has your organisation established a set target for reduction of GHG emissions?`,
                    question:
`only respond with "yes" or "no": Has the organisation established a set target for reduction of GHG emissions?`
                },
                {
                    id: `Has the organisation implemented an environmental management system to manage environmental matters?`,
                    question:
`only respond with "yes" or "no": Has the organisation implemented an environmental management system to manage environmental matters?`
                },
                {
                    id: `Has this been externally certified e.g. to ISO 14001 or equivalent standard?`,
                    question:
`only respond with "yes" or "no": Has this been externally certified e.g. to ISO 14001 or equivalent standard?`
                }
            ],
            'Resource Usage Questions': [
                {
                    id: `_Does your organisation have any policies and/or procedures documented regarding your organisation's water use?`,
                    question:
`only respond with "yes" or "no": Does your organisation have any policies and/or procedures documented regarding your organisation's water use?`
                },
                {
                    id: `Please rate the importance of water quality and quantity to the success of your business.`,
                    question:
`Please rate the importance of water quality and quantity to the success of your business? only answer with a choice from the following list: 
                            
Not very important
Somewhat important
Very important
Vital

if you don't know, respond with ''`,
                },
                {
                    id: `Do you record water use at your offices/work sites/ etc?`,
                    question:
`only respond with "yes" or "no": Do you record water use at your offices/work sites/ etc?`
                },
                {
                    id: `_Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?`,
                    question:
`only respond with "yes" or "no": Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?`
                },
                {
                    id: `_Does your organisation have any policies and/or procedures governing your organisation's management of waste?`,
                    question:
`only respond with "yes" or "no": Does your organisation have any policies and/or procedures governing your organisation's management of waste?`
                },
                {
                    id: `_Does your organisation have any policies and/or procedures governing your organisation's energy use?`,
                    question:
`only respond with "yes" or "no": Does your organisation have any policies and/or procedures governing your organisation's management of waste?`
                },
                {
                    id: `What proportion of your total energy use is derived from renewable sources (e.g. wind, hydro, solar)?`,
                    question:
`What proportion of your total energy use is derived from renewable sources (e.g. wind, hydro, solar)?`
                },
                {
                    id: `Do you have any means of generating electricity yourself?`,
                    question:
`only respond with "yes" or "no": Do you have any means of generating electricity yourself?`
                },
                {
                    id: `Please rate the importance of road and aviation transport to the success of your business.`,
                    question:
`Please rate the importance of road and aviation transport to the success of your business? only answer with a choice from the following list: 
            
Not very important
Somewhat important
Very important
Vital

if you don't know, respond with 'I don't know'`,
                },
                {
                    id: `Does your organisation trade in carbon credits?`,
                    question:
`only respond with "yes" or "no": Does your organisation trade in carbon credits?`
                }
            ],
            'Social Questions': [
                {
                    id: `_Does your organisation have a human rights policy?`,
                    question:
`only respond with "yes" or "no": Does your organisation have a human rights policy?`
                },
                {
                    id: `_Does your organisation have a human rights policy?`,
                    question:
`only respond with "yes" or "no": Does your organisation have a human rights policy?`
                },
                {
                    id: `_Please upload your modern slavery statement (if available)`,
                    question:
`only respond with "yes" or "no": Does your organisation have a modern slavery statement?`
                },
                {
                    id: `Does your organisation monitor modern slavery in any manner?`,
                    question:
`only respond with "yes" or "no": Does your organisation monitor modern slavery in any manner?`
                },
                {
                    id: `_Does your organisation have published and available WHS procedures and/or policies?`,
                    question:
`only respond with "yes" or "no": Does your organisation have published and available WHS procedures and/or policies?`
                },
                {
                    id: `_Does your organisation have formal procedures relating to workplace risk including monitoring accidents and incidents?`,
                    question:
`only respond with "yes" or "no": Does your organisation have published and available WHS procedures and/or policies?`
                },
                {
                    id: `What percentage of your staff are employed on a full-time basis?`,
                    question:
`What percentage of your staff are employed on a full-time basis?`
                },
                {
                    id: `What proportion of your workforce is covered by an enterprise bargaining agreement?`,
                    question:
`What proportion of your workforce is covered by an enterprise bargaining agreement?`
                },
                {
                    id: `_Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?`,
                    question:
`only respond with "yes" or "no": Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?`
                },
                {
                    id: `What is the gender of your organisation's Head of Business (i.e. CEO or equivalent)?`,
                    question:
`What is the gender of your organisation's Head of Business (i.e. CEO or equivalent)?
only answer with a choice from the following list:
Male
Female
Non-Binary
`
                },
                {
                    id: `What percentage of your board are women?`,
                    question:
`What percentage of your board are women?`
                },
                {
                    id: `What percentage of your senior management are women?`,
                    question:
`What percentage of your senior management are women?`
                },
                {
                    id: `What percentage of your board are PoC?`,
                    question:
`What percentage of your board are PoC?`
                },
                {
                    id: `What percentage of your senior management are PoC?`,
                    question:
`What percentage of your senior management are PoC?`
                },
                {
                    id: `_Have you undertaken a gender or ethnic diversity pay gap study?`,
                    question:
`only respond with "yes" or "no": Have you undertaken a gender or ethnic diversity pay gap study?`
                },
                {
                    id: `_Do you have a sexual harassment policy?`,
                    question:
`only respond with "yes" or "no": Do you have a sexual harassment policy?`
                },
                {
                    id: `Does your organisation have a target for female representation for your Board of Directors?`,
                    question:
`only respond with "yes" or "no": Does your organisation have a target for female representation for your Board of Directors?`
                },
                {
                    id: `_Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?`,
                    question:
`only respond with "yes" or "no": Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?`
                },
                {
                    id: `Is your organisation a member of any industry associations or cooperatives?`,
                    question:
`Is your organisation a member of any industry associations or cooperatives?`
                },
                {
                    id: `What kind of community outreach or engagement has your organisation participated in?`,
                    question:
`What kind of community outreach or engagement has your organisation participated in?`
                },
            ],
            'Governance Questions':[
                {
                    id: `Are the financial statements prepared in keeping with recognised accounting standards (e.g., AASB, IFRS or U.S. GAAP)?`,
                    question:
`only respond with "yes" or "no": Are the financial statements prepared in keeping with recognised accounting standards (e.g., AASB, IFRS or U.S. GAAP)?`
                },
                {
                    id: `Have there been any material re-statements of the Organisation’s financial statements in the past 5 years?`,
                    question:
`only respond with "yes" or "no": Have there been any material re-statements of the Organisation’s financial statements in the past 5 years?`
                },
                {
                    id: `Does your organisation's financial statements disclose any of the following items? Major transactions`,
                    question:
`only respond with "yes" or "no": Does your organisation's financial statements disclose any of the following items? Major transactions`
                },
                {
                    id: `Does your organisation's financial statements disclose any of the following items? Related party transactions`,
                    question:
`only respond with "yes" or "no": Does your organisation's financial statements disclose any of the following items? Related party transactions`
                },
                {
                    id: `Does your organisation's financial statements disclose any of the following items? Major transactions`,
                    question:
`only respond with "yes" or "no": Does your organisation's financial statements disclose any of the following items? Major transactions`
                },
                {
                    id: `Does your organisation's financial statements disclose any of the following items? Off-balance sheet activities`,
                    question:
`only respond with "yes" or "no": Does your organisation's financial statements disclose any of the following items? Off-balance sheet activities`
                },
                {
                    id: `Does your organisation's financial statements disclose any of the following items? Other material events`,
                    question:
`only respond with "yes" or "no": Does your organisation's financial statements disclose any of the following items? Other material events`
                },
                {
                    id: `Does your Organisation's Board of Directors/Audit Committee review key elements of the Organisation’s financial statements?`,
                    question:
`only respond with "yes" or "no": Does your Organisation's Board of Directors/Audit Committee review key elements of the Organisation’s financial statements?`
                },
                {
                    id: `_Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?`,
                    question:
`only respond with "yes" or "no": Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?`
                },
                {
                    id: `Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?`,
                    question:
`Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?`
                },
                {
                    id: `Has your Organisation ever been sanctioned or censured by the regulator or the exchange for any failure or delay in disclosing required information to the public?`,
                    question:
`only respond with "yes" or "no": Has your Organisation ever been sanctioned or censured by the regulator or the exchange for any failure or delay in disclosing required information to the public?`
                },
                {
                    id: `Is the Organisation’s external audit in line with International Standards on Auditing (ISA)?`,
                    question:
`only respond with "yes" or "no": Is the Organisation’s external audit in line with International Standards on Auditing (ISA)?`
                },
                {
                    id: `Does your Organisation have an internal audit function?`,
                    question:
`only respond with "yes" or "no": Does your Organisation have an internal audit function?`
                },
                {
                    id: `Does your Organisation have a risk management system?`,
                    question:
`only respond with "yes" or "no": Does your Organisation have a risk management system?`
                },
                {
                    id: `If yes, how would you describe it compared to industry standards?`,
                    question:
`Does your Organisation have a risk management system? compared to industry standards?`
                },
                {
                    id: `_Does your organisation have a whistleblower policy or procedure?`,
                    question:
`only respond with "yes" or "no": Does your organisation have a whistleblower policy or procedure?`
                },
                {
                    id: `Does your organisation have a Whistleblower Hotline?`,
                    question:
`only respond with "yes" or "no": Does your organisation have a Whistleblower Hotline?`
                },
                {
                    id: `Is your hotline operated by an independent third party?`,
                    question:
`only respond with "yes" or "no": Is your hotline operated by an independent third party?`
                },
                {
                    id: `How many reports were made to the hotline in the past twelve month period?`,
                    question:
`How many reports were made to the hotline in the past twelve month period?`
                },
                {
                    id: `What proportion of the above reports required remediation?`,
                    question:
`What proportion of the above reports required remediation?`
                },
                {
                    id: `How many independent directors do you have?`,
                    question:
`How many independent directors do you have?`
                },
                {
                    id: `Does your organisation have any Board Committees?`,
                    question:
`only respond with "yes" or "no": Does your organisation have any Board Committees?`
                },
                {
                    id: `What proportion of the above reports required remediation?`,
                    question:
`only respond with "yes" or "no": What proportion of the above reports required remediation?`
                },
                {
                    id: `How often does the board of directors meet?`,
                    question:
`How often does the board of directors meet?
only answer with a choice from the following list: 
                            
None
Annually
Semi Annually
Quarterly
Monthly
Weekly
                    
if you don't know, respond with 'I don't know'`
                },
                {
                    id: `How often is the Board of Directors elected?`,
                    question:
`How often is the Board of Directors elected?
only answer with a choice from the following list: 
                            
Quarterly
Semi Annually
Annually
Biannually
Other
                        
if you don't know, respond with 'I don't know'`
                }
            ]
        };
        
        console.log(activeQuestion, questions[activeQuestion])
        axios.post(
            `${azure_function_url}/LLMModel?command=flow`, 
            {
                model: 'gpt-4',
                json: [
                    {
                        "context": context
                    },
                    {
                        "thread": '_flow_'
                    },
                    {
                        "model": "gpt-4"
                    },
                    {
                        "prompt": "first",
                        "rag": [
                            {
                                "module": "9ac85e2c-4fc0-49e2-b350-6a0c279131f7",
                                "description": 
                                    `
1. "EMECO" is a RAG tool.
2. Use the "EMECO" RAG tool to search for any topic or context related to EMECO.
3. If there are more than one subject, create several queries, one query per subject. 
4. For example if the query is about A and/or B then first ask about A then B.
                                    `
                            }
                        ],
                        "temperature": 0,
                        // "instructions": questions[activeQuestion].map((x:any) => `you are an agent that is responding to questions in a survey. \n\n only answer the question based on the information available in the RAG tool. \n\n --- \n\n ${x.question}`)
                        "instructions": questions[activeQuestion].map((x:any) => `${x.question}`)
                    }
                ]
            },
            {
                headers: {
                    'Authorization': parent.bearerToken
                }
        }).then(res => {
            console.log(res)
            let i = 0;
            const values = res.data.prompts[0].output.reduce((acc: any, item: any) => {
                const keyValue = questions[activeQuestion][i].id;
                i++;
                if (typeof keyValue === 'string' || typeof keyValue === 'number') {
                    if(item === 'Yes' || item === 'Yes.' || item === 'yes.')
                        acc[keyValue] = 'yes';
                    else if(item === 'No' || item === 'No.' || item === 'no.')
                        acc[keyValue] = 'no';
                    else
                        acc[keyValue] = item;
                }
                return acc;
            }, {} as Record<string, any>);

            if (values['Has your organisation established a set target for reduction of GHG emissions?']) {
                setGhgTarget(values['Has your organisation established a set target for reduction of GHG emissions?']);
            }

            console.log(values);
            form.setFieldsValue(values);
            
            const id = activeQuestion;
            if(!(id in formData))
                formData[id] = {};

            formData[id] = mergeObjects(formData[id], values)

            console.log(formData);

            setFormData(formData);

            setMagic(false);
            setProcessCounter((x)=>x+1);
            setCounter((x)=>x+1);
            
        }).catch(err => {console.log(err);message.error(err.toString())});
    };


    useEffect(() => {

        const _questions = [
            { 
                id: 'General Questions', label: 'General', q: 'Please tell us about the Organisation...',
                a:
                    <div 
                        style={{
                            justifyContent: "center" /* Aligns children (the form) in the center horizontally */
                        }}
                    >
                        {isMagic && (
                            <Spin tip="Loading...">
                                <>
                                <Alert
                                    message="Working on it..."
                                    description="I need a second to work my magic!"
                                    type="info"
                                />
                                <br></br>
                                </>
                            </Spin>
                        )}
                        <Form
                            form={form}
                            disabled={isMagic}
                            labelCol= {{ span: 6 }}
                            wrapperCol= {{ span: 14 }}
                            onValuesChange={async (changedValues, allValues) => {
                                console.log('--- change ---', changedValues, allValues)
                                await processValueChange('General Questions', changedValues, allValues);
                                
                                if (changedValues["Organisation Type"]) {
                                    setOrganisationType(changedValues["Organisation Type"]);
                                }
                            }}
                            initialValues={formData['General Questions']}
                            onFinish={values => {
                                setThread('Environmental Questions');
                                scrollToId('Environmental Questions');
                            }}
                        >
                            <Form.Item 
                                name="Organisation Name" 
                                label="Organisation Name"
                                rules={[{
                                        required: true,
                                        message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Organisation Type"
                                label="Organisation Type"
                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Select placeholder="Please select your type of Organisation">
                                    <Option value="Organisation_public">Organisation (Public)</Option>
                                    <Option value="Organisation_private">Organisation (Private)</Option>
                                    <Option value="sole_trader">Sole Trader</Option>
                                    <Option value="trust">Trust</Option>
                                    <Option value="partnership">Partnership</Option>
                                    <Option value="joint_venture">Joint Venture</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="ACN / ABN" 
                                label="ACN / ABN"
                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Business Address" 
                                label="Business Address"
                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                label="Industries"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Form.List name={['Industry', 'list']}>
                                    {(subFields, subOpt) => (
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                        {subFields.map((subField) => (
                                        <Card 
                                            size="small"
                                            key={subField.key}
                                            extra={
                                                <CloseOutlined
                                                  onClick={() => {
                                                    subOpt.remove(subField.name);
                                                  }}
                                                />
                                              }
                                        >
                                            <Form.Item 
                                                label="Type"  
                                                name={[subField.name, "Select which business activity best describes your organisation's activities. If you have more than one activity, please attribute your best estimate of the percentage of revenue generated from each activity"]}

                                                rules={[{
                                                    required: true,
                                                    message: 'Please answer!'
                                                }]}                
                                            >
                                                <Select placeholder="Select which business activity best describes your organisation's activities. If you have more than one activity, please attribute your best estimate of the percentage of revenue generated from each activity">
                                                    <Option value="Agriculture, forestry and fishing">Agriculture, forestry and fishing</Option>
                                                    <Option value="Mining and quarrying">Mining and quarrying</Option>
                                                    <Option value="manufacturing">Manufacturing</Option>
                                                    <Option value="Electricity, gas, steam and air conditioning supply">Electricity, gas, steam and air conditioning supply</Option>
                                                    <Option value="Water supply; sewerage, waste management and remediation activities">Water supply; sewerage, waste management and remediation activities</Option>
                                                    <Option value="Construction">Construction</Option>
                                                    <Option value="Wholesale and retail trade; repair of motor vehicles and motorcycles">Wholesale and retail trade; repair of motor vehicles and motorcycles</Option>
                                                    <Option value="Transportation and storage">Transportation and storage</Option>
                                                    <Option value="Accommodation and food service activities">Accommodation and food service activities</Option>
                                                    <Option value="Information and communication">Information and communication</Option>
                                                    <Option value="Financial and insurance activities">Financial and insurance activities</Option>
                                                    <Option value="Real estate activities">Real estate activities</Option>
                                                    <Option value="Professional, scientific and technical activities">Professional, scientific and technical activities</Option>
                                                    <Option value="Administrative and support service activities">Administrative and support service activities</Option>
                                                    <Option value="Public administration and defence; compulsory social security">Public administration and defence; compulsory social security</Option>
                                                    <Option value="Education">Education</Option>
                                                    <Option value="Human health and social work activities">Human health and social work activities</Option>
                                                    <Option value="Arts, entertainment and recreation">Arts, entertainment and recreation</Option>
                                                    <Option value="Other service activities">Other service activities</Option>
                                                    <Option value="Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use">Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use</Option>
                                                    <Option value="Activities of extraterritorial organizations and bodies">Activities of extraterritorial organizations and bodies</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item 
                                                label="Size of Business (A$ annual revenue)" 
                                                name={[subField.name, 'Size of Business (A$ annual revenue)']}

                                                rules={[{
                                                    required: true,
                                                    message: 'Please answer!'
                                                }]}                
                                            >
                                                <Radio.Group>
                                                    <Radio value="0-20%">0-20%</Radio>
                                                    <Radio value="21-50%">21-50%</Radio>
                                                    <Radio value="51-80%">51-80%</Radio>
                                                    <Radio value="81-100%">81-100%</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Card>
                                        ))}
                                        <Button type="dashed" onClick={() => subOpt.add()} block>
                                        + Add Industry
                                        </Button>
                                    </div>
                                    )}
                                </Form.List>
                            </Form.Item>
                            <br></br>
                            <Form.Item label="Operating Locations">
                                <Form.List name={['Operating Locations', 'list']}>
                                    {(subFields, subOpt) => (
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                        {subFields.map((subField) => (
                                        <Card 
                                            size="small"
                                            key={subField.key}
                                            extra={
                                                <CloseOutlined
                                                  onClick={() => {
                                                    subOpt.remove(subField.name);
                                                  }}
                                                />
                                              }
                                        >
                                            <Form.Item label="Name"  name={[subField.name, 'Operating Locations']}>
                                                <Select placeholder="Please select a country">
                                                {
                                                    getData().map(x=>(
                                                        <Option key={x.code} value={x.name}>{x.name}</Option>
                                                    ))
                                                }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Revenue" name={[subField.name, 'Revenue by Country']}>
                                                <Radio.Group>
                                                    <Radio value="0-20%">0-20%</Radio>
                                                    <Radio value="21-50%">21-50%</Radio>
                                                    <Radio value="51-80%">51-80%</Radio>
                                                    <Radio value="81-100%">81-100%</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Card>
                                        ))}
                                        <Button type="dashed" onClick={() => subOpt.add()} block>
                                        + Add Country
                                        </Button>
                                    </div>
                                    )}
                                </Form.List>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="A$ Annual Revenue" 
                                label="A$ Annual Revenue"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Select placeholder="Please select the size of the Organisation in terms of annual A$ revenue">
                                    <Option value="$0-10 million">$0-10 million</Option>
                                    <Option value="$10-50 million">$10-50 million</Option>
                                    <Option value="$50-100 million">$50-100 million</Option>
                                    <Option value="$100-200 million">$100-200 million</Option>
                                    <Option value="$200 million+">$200 million+</Option>
                                </Select>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Number of employees (total workforce incl part-time, casual, contracted)" 
                                label="Nr of employees"
                                help="Number of employees (total workforce incl part-time, casual, contracted)"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0-10">0-10</Radio>
                                    <Radio value="11-50">11-50</Radio>
                                    <Radio value="51-100">51-100</Radio>
                                    <Radio value="101+">101+</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Divider></Divider>
                            <br></br>
                            <Form.Item
                                wrapperCol={{
                                    span: 14,
                                    offset: 6, // Adjust the offset to align the button to the right
                                }}
                            >
                                <Button style={{ width:'50%' }} type="primary" htmlType="submit">Next</Button>
                                <Button
                                    icon={<ExperimentOutlined />} 
                                    style={{ width:'calc(50% - 10px)', marginLeft:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        magicWand('General Questions');
                                    }}
                                >
                                    Magic
                                </Button>
                            </Form.Item>
                        </Form> 
                    </div>
            },
            { 
                id: 'Environmental Questions', label: 'Environmental', q: 'Let\'s discuss the Organisation\'s environmental information...',
                a:
                    <div 
                        style={{
                            justifyContent: "center" /* Aligns children (the form) in the center horizontally */
                        }}
                    >
                        <Form
                            form={form}
                            disabled={isMagic}
                            labelCol= {{ span: 6 }}
                            wrapperCol= {{ span: 14 }}
                            onValuesChange={async (changedValues, allValues) => {
                                await processValueChange('Environmental Questions', changedValues, allValues);
                                
                                if (changedValues['Has your organisation established a set target for reduction of GHG emissions?']) {
                                    setGhgTarget(changedValues['Has your organisation established a set target for reduction of GHG emissions?']);
                                }

                                setCounter(x=>x+1)
                            }}
                            initialValues={formData['Environmental Questions']}
                            onFinish={values => {
                                setThread('Resource Usage Questions');
                                scrollToId('Resource Usage Questions');
                            }}
                        >
                            <br></br>
                            <Divider orientation="left">Emissions</Divider>
                            <Form.Item 
                                name="Does your organisation measure its direct greenhouse gas (GHG) emissions from controlled or owned sources (i.e., Scope 1 emissions) and indirect GHG emissions from the generation of purchased energy (i.e., Scope 2 emissions)?" 
                                label="GHG" 
                                help="Does your organisation measure its direct greenhouse gas (GHG) emissions from controlled or owned sources (i.e., Scope 1 emissions) and indirect GHG emissions from the generation of purchased energy (i.e., Scope 2 emissions)?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Has your organisation established a set target for reduction of GHG emissions?" 
                                label="GHG Target" 
                                help="Has your organisation established a set target for reduction of GHG emissions?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            {ghgTarget === 'yes' && (
                            <>
                            <Divider orientation="left"></Divider>
                            <br></br>
                            <Form.Item 
                                name="GHG Scope 1 Actual" 
                                label="Scope 1 Actual" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="GHG Scope 1 Target" 
                                label="Scope 1 Target" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider orientation="left"></Divider>
                            <br></br>
                            <Form.Item 
                                name="GHG Scope 2 Actual" 
                                label="Scope 2 Actual" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="GHG Scope 2 Target" 
                                label="Scope 2 Target" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider orientation="left"></Divider>
                            <br></br>
                            
                            <Form.Item 
                                name="GHG Scope 3 Actual" 
                                label="Scope 3 Actual" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="GHG Scope 3 Target" 
                                label="Scope 3 Target" 
                                help="If yes, please describe below, including your organisation’s performance against the target."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider orientation="left"></Divider>
                            <br></br>
                            </>)}
                        
                            <Form.Item 
                                name="Has the organisation implemented an environmental management system to manage environmental matters?" 
                                label="Implementation" 
                                help="Has the organisation implemented an environmental management system to manage environmental matters?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Has this been externally certified e.g. to ISO 14001 or equivalent standard?" 
                                label="ISO 14001" 
                                help="Has this been externally certified e.g. to ISO 14001 or equivalent standard?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider></Divider>
                            <br></br>
                            <Form.Item
                                wrapperCol={{
                                    span: 14,
                                    offset: 6, // Adjust the offset to align the button to the right
                                }}
                            >
                                
                                <Button 
                                    style={{ width:'calc(33.33% - 10px)', marginRight:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        setThread('General Questions');
                                        scrollToId('General Questions');
                                    }}
                                >
                                    Back
                                </Button>
                                <Button style={{ width:'33.33%' }} type="primary" htmlType="submit">Next</Button>
                                <Button
                                    icon={<ExperimentOutlined />} 
                                    style={{ width:'calc(33.33% - 10px)', marginLeft:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        magicWand('Environmental Questions');
                                    }}
                                >
                                    Magic
                                </Button>
                                
                            </Form.Item>
                        </Form> 
                    </div>
            },
            { 
                id: 'Resource Usage Questions', label: 'Resource Usage', q: 'We need to gather information about the Organisation\'s resource usage...',
                a:
                    <div 
                        style={{
                            justifyContent: "center" /* Aligns children (the form) in the center horizontally */
                        }}
                    >
                        {isMagic && (
                            <Spin tip="Loading...">
                                <>
                                <Alert
                                    message="Working on it..."
                                    description="I need a second to work my magic!"
                                    type="info"
                                />
                                <br></br>
                                </>
                            </Spin>
                        )}
                        <Form
                            form={form}
                            disabled={isMagic}
                            labelCol= {{ span: 6 }}
                            wrapperCol= {{ span: 14 }}
                            onValuesChange={async (changedValues, allValues) => {
                                await processValueChange('Resource Usage Questions', changedValues, allValues);
                            }}
                            initialValues={formData['Resource Usage Questions']}
    
                            onFinish={values => {
                                setThread('Social Questions');
                                scrollToId('Social Questions');
                            }}
                        >
                            <Divider orientation="left">Water</Divider>
                            <Form.Item 
                                name="_Does your organisation have any policies and/or procedures documented regarding your organisation's water use?" 
                                label="Policies" 
                                help="Does your organisation have any policies and/or procedures documented regarding your organisation's water use?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Resource Usage Questions' in formData ? formData['Resource Usage Questions']["_Does your organisation have any policies and/or procedures documented regarding your organisation's water use?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Resource Usage Questions', "_Does your organisation have any policies and/or procedures documented regarding your organisation's water use?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Please rate the importance of water quality and quantity to the success of your business." 
                                label="Importance" 
                                help="Please rate the importance of water quality and quantity to the success of your business."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value=">Not very important">Not very important</Radio>
                                    <Radio value="Somewhat important">Somewhat important</Radio>
                                    <Radio value="Very important">Very important</Radio>
                                    <Radio value="Vital">Vital</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Do you record water use at your offices/work sites/ etc?" 
                                label="Record" 
                                help="Do you record water use at your offices/work sites/ etc?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What proportion of your total water use is from self-sufficient sources e.g. rainwater?" 
                                label="Usage" 
                                help="What proportion of your total water use is from self-sufficient sources e.g. rainwater?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What proportion of your organisation’s total water usage comes from recycled or reused sources?" 
                                label="Recycle" 
                                help="What proportion of your organisation’s total water usage comes from recycled or reused sources?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
    
                            <Divider orientation="left">Land</Divider>
                            <Form.Item 
                                name="_Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?" 
                                label="Policies" 
                                help="Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Resource Usage Questions' in formData ? formData['Resource Usage Questions']["_Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Resource Usage Questions', "_Does your organisation have any policies and/or procedures governing your organisation's use of land and/or management of biodiversity?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Please rate the intensity of land usage for your organisation, in terms of your operations and business model." 
                                label="Usage" 
                                help="Please rate the intensity of land usage for your organisation, in terms of your operations and business model."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="No use of land resources">No use of land resources</Radio>
                                    <Radio value="Land resources are used but not intensively">Land resources are used but not intensively</Radio>
                                    <Radio value="Land resources are used somewhat intensively">Land resources are used somewhat intensively</Radio>
                                    <Radio value="Land resources are used quite intensively">Land resources are used quite intensively</Radio>
                                    <Radio value="Land resources are used very intensively">Land resources are used very intensively</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Please estimate the total square meters of building premises used by your organisation - include office, production, distribution, retail etc" 
                                label="Usage" 
                                help="Please estimate the total square meters of building premises used by your organisation - include office, production, distribution, retail etc"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            
                            <Divider orientation="left">Waste Management</Divider>
                            <Form.Item 
                                name="_Does your organisation have any policies and/or procedures governing your organisation's management of waste?" 
                                label="Policies" 
                                help="Does your organisation have any policies and/or procedures governing your organisation's management of waste?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Resource Usage Questions' in formData ? formData['Resource Usage Questions']["_Does your organisation have any policies and/or procedures governing your organisation's management of waste?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Resource Usage Questions', "_Does your organisation have any policies and/or procedures governing your organisation's management of waste?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>                            
                            <br></br>
                            <Form.Item 
                                name="Please select if your organisation deals with any of the following waste products." 
                                label="Types" 
                                help="Please select if your Organisation deals with any of the following waste products."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Select mode="multiple" placeholder="Please select if your organisation deals with any of the following waste products.">
                                    <Option value="Residential Waste">Residential Waste</Option>
                                    <Option value="Air pollutants">Air pollutants</Option>
                                    <Option value="Liquid effluents">Liquid effluents</Option>
                                    <Option value="Clean / or contaminated soil">Clean / or contaminated soil</Option>
                                    <Option value="Industrial Sludge">Industrial Sludge</Option>
                                    <Option value="Gravel & Rocks">Gravel & Rocks</Option>
                                    <Option value="Nuclear by-products">Nuclear by-products</Option>
                                    <Option value="Oil field waste">Oil field waste</Option>
                                    <Option value="Extraction or harvesting waste">Extraction or harvesting waste</Option>
                                    <Option value="Ferrous metals">Ferrous metals</Option>
                                    <Option value="Plastics">Plastics</Option>
                                </Select>
                            </Form.Item>


                            <br></br>
                            <Form.Item 
                                name="How does your organisation handle such waste products? Percentage of waste to landfill" 
                                label="Landfill" 
                                help="How does your organisation handle such waste products? Percentage of waste to landfill"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How does your organisation handle such waste products? Percentage of waste to recycling" 
                                label="Recycling" 
                                help="How does your organisation handle such waste products? Percentage of waste to recycling"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How does your organisation handle such waste products? Percentage of waste to be reused" 
                                label="Reuse" 
                                help="How does your organisation handle such waste products? Percentage of waste to be reused"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How does your organisation handle such waste products? Percentage of waste to be recovered" 
                                label="Recover" 
                                help="How does your organisation handle such waste products? Percentage of waste to be recovered"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            
                            <Divider orientation="left">Energy Usage</Divider>
                            <Form.Item 
                                name="_Does your organisation have any policies and/or procedures governing your organisation's energy use?" 
                                label="Policies" 
                                help="Does your organisation have any policies and/or procedures governing your organisation's energy use?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Resource Usage Questions' in formData ? formData['Resource Usage Questions']["_Does your organisation have any policies and/or procedures governing your organisation's energy use?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Resource Usage Questions', "_Does your organisation have any policies and/or procedures governing your organisation's energy use?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What proportion of your total energy use is derived from renewable sources (e.g. wind, hydro, solar)?" 
                                label="Usage" 
                                help="What proportion of your total energy use is derived from renewable sources (e.g. wind, hydro, solar)?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Do you have any means of generating electricity yourself?" 
                                label="Generation" 
                                help="Do you have any means of generating electricity yourself?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Please rate the importance of road and aviation transport to the success of your business." 
                                label="Importance" 
                                help="Please rate the importance of road and aviation transport to the success of your business."

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value=">Not very important">Not very important</Radio>
                                    <Radio value="Somewhat important">Somewhat important</Radio>
                                    <Radio value="Very important">Very important</Radio>
                                    <Radio value="Vital">Vital</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item
                                name="Does your organisation trade in carbon credits?" 
                                label="Carbon Credits" 
                                help="Does your organisation trade in carbon credits?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider></Divider>
                            <br></br>
                            <Form.Item
                                wrapperCol={{
                                    span: 14,
                                    offset: 6, // Adjust the offset to align the button to the right
                                }}
                            >
                                <Button 
                                    style={{ width:'calc(33.33% - 10px)', marginRight:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        setThread('Environmental Questions');
                                        scrollToId('Environmental Questions');
                                    }}
                                >
                                    Back
                                </Button>
                                <Button style={{ width:'33.33%' }} type="primary" htmlType="submit">Next</Button>
                                <Button
                                    icon={<ExperimentOutlined />} 
                                    style={{ width:'calc(33.33% - 10px)', marginLeft:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        magicWand('Resource Usage Questions');
                                    }}
                                >
                                    Magic
                                </Button>
                            </Form.Item>
                        </Form> 
                    </div>
            },
            { 
                id: 'Social Questions', label: 'Social', q: 'Social responsibility is important. Let\'s discuss your policies...',
                a:
                    <div 
                        style={{
                            justifyContent: "center" /* Aligns children (the form) in the center horizontally */
                        }}
                    >
                        {isMagic && (
                            <Spin tip="Loading...">
                                <>
                                <Alert
                                    message="Working on it..."
                                    description="I need a second to work my magic!"
                                    type="info"
                                />
                                <br></br>
                                </>
                            </Spin>
                        )}
                        <Form
                            form={form}
                            disabled={isMagic}
                            labelCol= {{ span: 6 }}
                            wrapperCol= {{ span: 14 }}

                            onValuesChange={async (changedValues, allValues) => {
                                await processValueChange('Social Questions', changedValues, allValues);
                            }}
                            initialValues={formData['Social Questions']}
                            onFinish={values => {
                                setThread('Governance Questions');
                                scrollToId('Governance Questions');
                            }}
                        >
                            <Divider orientation="left">Human Rights</Divider>
                            <Form.Item 
                                name="_Does your organisation have a human rights policy?" 
                                label="Human Rights" 
                                help="Does your organisation have a human rights policy?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Does your organisation have a human rights policy?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Social Questions', "_Does your organisation have a human rights policy?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Please upload your modern slavery statement (if available)" 
                                label="Modern Slavery Statements" 
                                help="Please upload your modern slavery statement (if available)"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Please upload your modern slavery statement (if available)"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Social Questions', "_Please upload your modern slavery statement (if available)", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation monitor modern slavery in any manner?" 
                                label="Monitoring" 
                                help="Does your organisation monitor modern slavery in any manner?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
    
                            <Divider orientation="left">Labour Rights & WHS</Divider>
                            <Form.Item 
                                name="_Does your organisation have published and available WHS procedures and/or policies?" 
                                label="Labour Rights" 
                                help="Does your organisation have published and available WHS procedures and/or policies?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Does your organisation have published and available WHS procedures and/or policies?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Social Questions', "_Does your organisation have published and available WHS procedures and/or policies?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Does your organisation have formal procedures relating to workplace risk including monitoring accidents and incidents?" 
                                label="Monitoring" 
                                help="Does your organisation have formal procedures relating to workplace risk including monitoring accidents and incidents?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Does your organisation have formal procedures relating to workplace risk including monitoring accidents and incidents?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Social Questions', "_Does your organisation have formal procedures relating to workplace risk including monitoring accidents and incidents?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What percentage of your staff are employed on a full-time basis?" 
                                label="Full Timers" 
                                help="What percentage of your staff are employed on a full-time basis?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What proportion of your workforce is covered by an enterprise bargaining agreement?" 
                                label="Bargaining Agreement" 
                                help="What proportion of your workforce is covered by an enterprise bargaining agreement?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
    
                            <Divider orientation="left">Gender & Diversity</Divider>
                            <Form.Item 
                                name="_Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?" 
                                label="Labour Rights" 
                                help="Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Social Questions', "_Does your organisation have policies and procedures in place to ensure equal opportunities and promote diversity in your recruitment process, workforce, leadership and/or board representation?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What is the gender of your organisation's Head of Business (i.e. CEO or equivalent)?" 
                                label="CEO" 
                                help="What is the gender of your organisation's Head of Business (i.e. CEO or equivalent)?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="Female">Female</Radio>
                                    <Radio value="Male">Male</Radio>
                                    <Radio value="Non-Binary">Non-Binary</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What percentage of your board are women?" 
                                label="Board Female" 
                                help="What percentage of your board are women?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What percentage of your senior management are women?" 
                                label="Seniors Female" 
                                help="What percentage of your senior management are women?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What percentage of your board are PoC?" 
                                label="Board PoC" 
                                help="What percentage of your board are PoC?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What percentage of your senior management are PoC?" 
                                label="Seniors PoC" 
                                help="What percentage of your senior management are PoC?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Have you undertaken a gender or ethnic diversity pay gap study?" 
                                label="Pay Gap Study" 
                                help="Have you undertaken a gender or ethnic diversity pay gap study?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Have you undertaken a gender or ethnic diversity pay gap study?"]?.fileList : []} 
                                    onChange={(file)=> { fileChange('Social Questions', "_Have you undertaken a gender or ethnic diversity pay gap study?", file) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Do you have a sexual harassment policy?" 
                                label="Sexual Harassment Policy" 
                                help="Do you have a sexual harassment policy?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Do you have a sexual harassment policy?"]?.fileList : []} 
                                    onChange={(file)=> { fileChange('Social Questions', "_Do you have a sexual harassment policy?", file) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation have a target for female representation for your Board of Directors?" 
                                label="Female Target" 
                                help="Does your organisation have a target for female representation for your Board of Directors?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            
    
                            <Divider orientation="left">Community & Customer Impact</Divider>
                            <Form.Item 
                                name="_Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?" 
                                label="Policies" 
                                help="Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Social Questions' in formData ? formData['Social Questions']["_Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?"]?.fileList : []} 
                                    onChange={(file)=> { fileChange('Social Questions', "_Does your organisation have policies, procedures or directives relating to your organisation's corporate social responsibility?", file) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Is your organisation a member of any industry associations or cooperatives?" 
                                label="Associations" 
                                help="Is your organisation a member of any industry associations or cooperatives?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input />
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What kind of community outreach or engagement has your organisation participated in?" 
                                label="Outreach" 
                                help="What kind of community outreach or engagement has your organisation participated in?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Select mode="multiple" placeholder="Please select if your organisation deals with any of the following waste products.">
                                    <Option value="Non-charity volunteer working days">Non-charity volunteer working days</Option>
                                    <Option value="Community education / teaching"> Community education / teaching</Option>
                                    <Option value="Charitable volunteer work">Charitable volunteer work</Option>
                                    <Option value="Pro bono work / products ordinarily delivered for a fee">Pro bono work / products ordinarily delivered for a fee</Option>
                                    <Option value="One-off special community events">One-off special community events</Option>
                                    <Option value="Other">Other</Option>
                                    <Option value="No community initiatives">No community initiatives</Option>
                                </Select>
                            </Form.Item>
                            <br></br>
                            <Divider></Divider>
                            <br></br>
                            
                            <Form.Item
                                wrapperCol={{
                                    span: 14,
                                    offset: 6,
                                }}
                            >
                                <Button 
                                    style={{ width:'calc(33.33% - 10px)', marginRight:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        setThread('Resource Usage Questions');
                                        scrollToId('Resource Usage Questions');
                                    }}
                                >
                                    Back
                                </Button>
                                <Button style={{ width:'33.33%' }} type="primary" htmlType="submit">Next</Button>
                                <Button
                                    icon={<ExperimentOutlined />} 
                                    style={{ width:'calc(33.33% - 10px)', marginLeft:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        magicWand('Social Questions');
                                    }}
                                >
                                    Magic
                                </Button>
                            </Form.Item>
                        </Form> 
                    </div>
            },
            { 
                id: 'Governance Questions', label: 'Governance', q: 'Please tell us about your Organisation\'s governance...',
                a:
                    <div 
                        style={{
                            justifyContent: "center" /* Aligns children (the form) in the center horizontally */
                        }}
                    >
                        {isMagic && (
                            <Spin tip="Loading...">
                                <>
                                <Alert
                                    message="Working on it..."
                                    description="I need a second to work my magic!"
                                    type="info"
                                />
                                <br></br>
                                </>
                            </Spin>
                        )}
                        <Form
                            form={form}
                            disabled={isMagic}
                            labelCol= {{ span: 6 }}
                            wrapperCol= {{ span: 14 }}
                            onValuesChange={async (changedValues, allValues) => {
                                await processValueChange('Governance Questions', changedValues, allValues);
                            }}
                            initialValues={formData['Governance Questions']}
                            onFinish={values => {
                                setThread('Resource Usage Questions');
                                scrollToId('Resource Usage Questions');
                            }}
                        >
                            <Divider orientation="left">Financials & Reporting</Divider>
                            <Form.Item 
                                name="Are the financial statements prepared in keeping with recognised accounting standards (e.g., AASB, IFRS or U.S. GAAP)?" 
                                label="Accounts" 
                                help="Are the financial statements prepared in keeping with recognised accounting standards (e.g., AASB, IFRS or U.S. GAAP)?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Have there been any material re-statements of the Organisation’s financial statements in the past 5 years?" 
                                label="Statements" 
                                help="Have there been any material re-statements of the Organisation’s financial statements in the past 5 years?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation's financial statements disclose any of the following items? Major transactions" 
                                label="Major Transactions" 
                                help="Does your organisation's financial statements disclose any of the following items? Major transactions"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation's financial statements disclose any of the following items? Related party transactions" 
                                label="Related Parties" 
                                help="Does your organisation's financial statements disclose any of the following items? Related party transactions"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation's financial statements disclose any of the following items? Off-balance sheet activities" 
                                label="Off-balance sheet" 
                                help="Does your organisation's financial statements disclose any of the following items? Off-balance sheet activities"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation's financial statements disclose any of the following items? Other material events" 
                                label="Other" 
                                help="Does your organisation's financial statements disclose any of the following items? Other material events"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your Organisation's Board of Directors/Audit Committee review key elements of the Organisation’s financial statements?" 
                                label="Board Review" 
                                help="Does your Organisation's Board of Directors/Audit Committee review key elements of the Organisation’s financial statements?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?" 
                                label="Policy" 
                                help="Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Governance Questions' in formData ? formData['Governance Questions']["_Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Governance Questions', "_Does your Organisation have a written information disclosure policy that seeks to make all material information available to stakeholders?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            { 'General Questions' in formData && formData['General Questions']['Organisation Type'] === 'Organisation_public' && (
                                <>
                                <Form.Item 
                                    name="Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?" 
                                    label="Exchange" 
                                    help="Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?"

                                    rules={[{
                                        required: true,
                                        message: 'Please answer!'
                                    }]}    
                                >
                                    <Input/>
                                </Form.Item>
                                <br></br>
                                <Form.Item 
                                    name="Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?" 
                                    label="Exchange Disclosure" 
                                    help="Has the regulator or the exchange (as applicable) required your Organisation to provide additional information or clarification in the annual report, financial statements or other disclosure?"

                                    rules={[{
                                        required: true,
                                        message: 'Please answer!'
                                    }]}    
                                >
                                    <Radio.Group>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <br></br>
                                <Form.Item 
                                    name="Has your Organisation ever been sanctioned or censured by the regulator or the exchange for any failure or delay in disclosing required information to the public?" 
                                    label="Exchange Sanctions" 
                                    help="Has your Organisation ever been sanctioned or censured by the regulator or the exchange for any failure or delay in disclosing required information to the public?"

                                    rules={[{
                                        required: true,
                                        message: 'Please answer!'
                                    }]}    
                                >
                                    <Radio.Group>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <br></br>
                                </>
                            )}
    
                            <Divider orientation="left">Risk Management</Divider>
                            <Form.Item 
                                name="Is the Organisation’s external audit in line with International Standards on Auditing (ISA)?" 
                                label="Audit" 
                                help="Is the Organisation’s external audit in line with International Standards on Auditing (ISA)?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your Organisation have an internal audit function?" 
                                label="Audit" 
                                help="Does your Organisation have an internal audit function?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your Organisation have a risk management system?" 
                                label="System" 
                                help="Does your Organisation have a risk management system?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="If yes, how would you describe it compared to industry standards?" 
                                label="Description" 
                                help="If yes, how would you describe it compared to industry standards?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="_Does your organisation have a whistleblower policy or procedure?" 
                                label="Policy" 
                                help="Does your organisation have a whistleblower policy or procedure?"
                            >
                                {/* <Dragger 
                                    style={{height:'250px'}} 
                                    {...props} 
                                    fileList={'Governance Questions' in formData ? formData['Governance Questions']["_Does your organisation have a whistleblower policy or procedure?"]?.fileList : []} 
                                    onChange={(info)=> { fileChange('Governance Questions', "_Does your organisation have a whistleblower policy or procedure?", info) }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag some knowledge</p>
                                    <p className="ant-upload-hint">
                                    Only pdf files please.
                                    </p>
                                </Dragger> */}
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation have a Whistleblower Hotline?" 
                                label="Hotline" 
                                help="Does your organisation have a Whistleblower Hotline?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Is your hotline operated by an independent third party?" 
                                label="Independent" 
                                help="Is your hotline operated by an independent third party?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How many reports were made to the hotline in the past twelve month period?" 
                                label="Quantity" 
                                help="How many reports were made to the hotline in the past twelve month period?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                
                                <Radio.Group>
                                    <Radio value="0 to 1">0 to 1</Radio>
                                    <Radio value="2 to 3">2 to 3</Radio>
                                    <Radio value="4 to 5">4 to 5</Radio>
                                    <Radio value=">5">{">5"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="What proportion of the above reports required remediation?" 
                                label="Remediation" 
                                help="What proportion of the above reports required remediation?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="0%">0%</Radio>
                                    <Radio value="<10%">{"<10%"}</Radio>
                                    <Radio value="10%-50%">10%-50%</Radio>
                                    <Radio value=">50%">{">50%"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>

                            <Divider orientation="left">Executive Management</Divider>
                            <Form.Item 
                                name="How many independent directors do you have?" 
                                label="Independent Directors" 
                                help="How many independent directors do you have?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Input />
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="Does your organisation have any Board Committees?" 
                                label="Committees" 
                                help="Does your organisation have any Board Committees?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How often does the board of directors meet?" 
                                label="Meeting Frequency" 
                                help="How often does the board of directors meet?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="None">None</Radio>
                                    <Radio value="Annually">Annually</Radio>
                                    <Radio value="Semi Annually">Semi Annually</Radio>
                                    <Radio value="Quarterly">Quarterly</Radio>
                                    <Radio value="Monthly">Monthly</Radio>
                                    <Radio value="Weekly">Weekly</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Form.Item 
                                name="How often is the Board of Directors elected?" 
                                label="Election Frequency" 
                                help="How often is the Board of Directors elected?"

                                rules={[{
                                    required: true,
                                    message: 'Please answer!'
                                }]}
                            >
                                <Radio.Group>
                                    <Radio value="Quarterly">Quarterly</Radio>
                                    <Radio value="Semi Annually">Semi Annually</Radio>
                                    <Radio value="Annually">Annually</Radio>
                                    <Radio value="Biannually">Biannually</Radio>
                                    <Radio value="Other">Other</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <br></br>
                            <Divider></Divider>
                            <br></br>
                            
                            <Form.Item
                                wrapperCol={{
                                    span: 14,
                                    offset: 6, // Adjust the offset to align the button to the right
                                }}
                            >
                                { !hasUndefinedProperty(formData['Governance Questions']) ?
                                <>
                                    <Button 
                                        style={{ width:'calc(50% - 10px)', marginRight:'10px' }} 
                                        type="default" 
                                        onClick={()=>{
                                            setThread('Social Questions');
                                            scrollToId('Social Questions');
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button style={{ width:'50%' }} type="primary" htmlType="submit">Next</Button>
                                </>
                                :
                                <>
                                    <Button 
                                        style={{ width:'calc(50%)' }} 
                                        type="default" 
                                        onClick={()=>{
                                            setThread('Social Questions');
                                            scrollToId('Social Questions');
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                    icon={<ExperimentOutlined />} 
                                    style={{ width:'calc(50% - 10px)', marginLeft:'10px' }} 
                                    type="default" 
                                    onClick={()=>{
                                        magicWand('Governance Questions');
                                    }}
                                >
                                    Magic
                                </Button>
                                    
                                </>
                                }
                            </Form.Item>
                        </Form> 
                    </div>
            }
        ]

        setQuestions(_questions);

        setQHash(_questions.reduce((obj, x) => {
            obj[x.id] = x;
            return obj;
        }, {} as Record<string,  any>));

        const it = _questions.map(x => getItem(formData[x.id]?._done === true, x.label, x.id));
        setMenuItems(it);
    }, [ghgTarget, OrganisationType, isMagic, activeQuestion]);

    useEffect(() => {
        const it = questions.map(x => getItem(formData[x.id]?._done === true, x.label, x.id));
        setMenuItems(it);
    },[questions]);

    
    useEffect(() => { 

        axios.get(
            `${azure_function_url}/Totalis?command=form_get&context=${context}&version=${version}`,
            {
                headers: {
                    'Authorization': parent.bearerToken
                }
        })
        .then(x => { 
            setLoading(false);

            console.log(x)
            
            if(x.data?.data){
                setFirstTime(false)
                
                const formD = x.data.data;
                console.log(formD)
                setFormData(formD);

                if ('Environmental Questions' in formD && formD['Environmental Questions']['Has your Organisation established a set target and strategy for reduction of GHG emissions?']) {
                    setGhgTarget(formD['Environmental Questions']['Has your Organisation established a set target and strategy for reduction of GHG emissions?']);
                }

                if ('General Questions' in formD && formD['General Questions']["Organisation Type"]) {
                    setOrganisationType(formD['General Questions']["Organisation Type"]);
                }

                setCounter((x)=>x+1);   
            }                

            const timer = setInterval(() => {
                
                const th = 'General Questions'; 
                console.log(th)
                setActiveQuestion(th); setThread(th); scrollToId(th); 
                
                setCounter((x)=>x+1);
                clearInterval(timer);
            }, 1000); // Adjust speed of typing here
        })
        .catch(x => console.log(x));
        
        
    }, []);

    useEffect(() => {
        setLoadingFiles({ state: true });
        axios.get(
            `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
            {
                headers: {
                  'Authorization': parent.bearerToken
                }
            }).then(res => {
            if(res.data){
                const files = res.data.map((row: any, i: number) => {
                    return {
                        uid: i,
                        id: row.id,
                        name: row.name,
                        status: 'done',
                        data: row.data,
                        added: row.added,
                        last_read: row.last_read,
                        initialChunkSize: row.initialChunkSize
                    };
                }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                setLoadingFiles({ state: false });
                setFileList(files);
            }
            else
                setLoadingFiles({ state: false });
        }).catch(err => {console.log(err);message.error(err.toString())});

    }, [context, thread]);

    const scrollToId = (id: string) => {
        setActiveTabKey('1')
        setActiveQuestion(id);
        const element = listContainerRef.current;
        if (element) {
            setTimeout(() => {
                (element)?.scrollIntoView({ behavior: "smooth" });
            }, 0);
        }
    };
   
    const getItem = (
        done: boolean,
        label: React.ReactNode,
        key: React.Key,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);

        };
      
        return {
            key,
            label: <InputLabel done={done} bearerToken={parent.bearerToken} context={context} label={label} keyVal={key} scrollToId={scrollToId} selectedThread={thread} handleAddClick={handleAddClick} setThread={setThread}/>,
            type,
        } as MenuItem;
    }
    useEffect(() => {
        const activeMessage = qHash[activeQuestion];

        scrollToId(activeQuestion);
        setThread(activeQuestion);

        if(activeMessage){

            let date = new Date();

            let index = 0;
            let prevText = '';

            const step = 10;

            const timer = setInterval(() => {
                    
                if (index < activeMessage.q.length + step) {

                    prevText = activeMessage.q.substring(0, index + step);

                    if(index < activeMessage.q.length - 1)
                        MessageTree[parent.context] = [
                            { id: activeMessage.id, author: 'User', content: prevText, input: '', data: undefined, timestamp: date, show: false, agent_reference: '', model: '', temperature: 0 }, 
                        ];
                    else
                        MessageTree[parent.context] = [
                            { id: activeMessage.id, author: 'User', content: prevText, input: '', data: undefined, timestamp: date, show: false, agent_reference: '', model: '', temperature: 0 }, 
                            { id: activeMessage.id, author: 'Skynet', content: activeMessage.a, data: '', input: '', timestamp: date, show: false, tags: [], docsearch: '', agent_reference: '', model: '', temperature: 0, signature: null, prompt_tokens: 0, completion_tokens: 0 }
                        ];

                    setMessages(MessageTree[parent.context]);
                    index += step;
                } else {
                    clearInterval(timer);
                }
            }, 1); // Adjust speed of typing here

            const it = questions.map(x => getItem(formData[x.id]?._done === true, x.label, x.id));
            setMenuItems(it);
            setCounter(x=>x+1);
        }
    }, [activeQuestion, questions]);

    const [firstTime, setFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    
    return (
        loading ? 
        <Card 
            bordered={false} 
            loading={loading}
            style={{
                width: '100%',
                height: '100%',
            }}
            />
        : firstTime ?
        <>
            <div 
                style={{ 
                    // backgroundColor: 'black',
                    padding: '50px 50px', 
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >   
                <Result
                    status="success"
                    title="Congratulations on starting the Impact360 Assessment"
                    subTitle="SIKE will assist you throughout the process."
                    extra={[
                        <Button 
                            style={{width:'100%'}} 
                            onClick={()=> { 

                                formData['_started'] = true;

                                axios.post(
                                    `${azure_function_url}/Totalis?command=form_upload&context=${context}&version=${version}`,
                                    { data: formData },
                                    {
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                })
                                .then(x => console.log(x))
                                .catch(x => console.log(x));
                                setFirstTime(false);
                            }} 
                            key="buy"
                        >
                            Continue
                        </Button>,
                      ]}
                />
            </div>
        </>
        :
        <>
            <div ref={listContainerRef}></div>
            <div 
                style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    maxHeight: 'calc(99vh - 90px)', 
                    top:'90px'
                }}
            >
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){// && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        width: 275, 
                        borderRadius:'9px', 
                        marginRight:'30px',
                        paddingTop: '25px',
                        overflowY: 'auto' ,
                        height: 'calc(100vh - 80px)',
                    }}
                    items={menuItems}
                />

                <Row 
                    gutter={20} 
                    style={{
                        width:'calc(100% - 275px', 
                        paddingRight: '25px', 
                        height: 'calc(100vh - 80px)'
                    }}
                >
                    <Col span={14}>
                        <Card 
                            bordered={false} 
                            loading={messages?.length === 0}
                            style={{
                                width: '100%',
                                marginRight:'30px',
                                height: '100%',
                            }}
                        >   
                            <Tabs
                                activeKey={activeTabKey}
                                defaultActiveKey="1"
                                type="card"
                                size="large"
                                onChange={(key) => setActiveTabKey(key)}
                                items={[
                                    {
                                        label: (
                                            <span>
                                                <MessageOutlined />
                                                Survey
                                            </span>
                                            ),
                                        key: '1',
                                        children: 
                                            <List
                                                style={{ 
                                                    width: isMobile || true ? '100%' : '75%',
                                                    margin: '0 auto',
                                                    overflow: 'auto', // Enables scrollbar when needed
                                                    height:'calc(100vh - 185px)', 
                                                }}
                                                itemLayout="vertical"
                                                dataSource={questions && messages ? messages?.map((x, i)=> { return {...x, key: i} }) : []}
                                                renderItem={(_message, i) => (
                                                    <>
                                                        <List.Item 
                                                            style={
                                                                i === messages.length - 1 ?
                                                                { display: 'flex', flexDirection: 'column-reverse' }
                                                                :
                                                                { display: 'flex', flexDirection: 'column-reverse' }
                                                            }
                                                        >
                                                            <div style={{ flexGrow: 1 }}>                                                        
                                                                <List.Item.Meta 
                                                                />
                                                                {
                                                                    _message.content !== '' ?
                                                                    <>
                                                                        {i > 0 && (<br></br>)}
                                                                        <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }}>{_message.content}</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <Spin tip="Loading...">
                                                                        <Alert
                                                                            message="Working on it..."
                                                                            description="I need a second to work my magic!"
                                                                            type="info"
                                                                        />
                                                                    </Spin>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <br></br>
                                                                    </>
                                                                }
                                                                <br></br>
                                                            </div>    
                                                        </List.Item>
                                                            
                                                    </>
                                                )}
                                            />
                                    },
                                    {
                                        label: (
                                            <span>
                                                <MessageOutlined />
                                                Files
                                            </span>
                                            ),
                                        key: '2',
                                        children:
                                            <>
                                                <Table 
                                                    dataSource={fileList?.map((x, i) => {
                                                        x.key = i;
                                                        return x;
                                                    })}
                                                    pagination={{hideOnSinglePage: true}}
                                                    loading={isLoadingFiles.state}
                                                >
                                                    <Column
                                                        title="Name"
                                                        key="name"
                                                        render={(_: any, record: any) => (
                                                            record.name
                                                        )}
                                                    />
                                                    <Column
                                                        title="Added"
                                                        key="added"
                                                        render={(_: any, record: any) =>
                                                            (<DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.added) / 1000)} />
                                                        )}
                                                    />
                                                    <Column
                                                        title="Delete"
                                                        key="delete"
                                                        render={(_: any, record: any) => (
                                                            <Button 
                                                                type="default" 
                                                                block
                                                                onClick={async ()=> { 
                                                                    setLoadingFiles({ state: true });
                                                                    const res = await axios.get(
                                                                        `${azure_function_url}/LLMModel?command=files_delete&id=${record.id}&context=${context}`,
                                                                        {
                                                                            headers: {
                                                                                'Authorization': parent.bearerToken
                                                                            },
                                                                        });
                                    
                                                                    setLoadingFiles({ state: false });
                                    
                                                                    const files = res.data.map((row: any, i: number) => {
                                                                        return {
                                                                            uid: i,
                                                                            id: row.id,
                                                                            name: row.name,
                                                                            status: 'done',
                                                                            data: row.data,
                                                                            added: row.added,
                                                                            last_read: row.last_read,
                                                                            initialChunkSize: row.initialchunksize
                                                                        };
                                                                    }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                                                        
                                                                    setFileList(files);
                                                                    setSikeUpdateCounter(x=>x=x+1);
                                                                }}
                                                            >
                                                                <DeleteOutlined />
                                                            </Button>
                                                        )}
                                                    /> 
                                                    <Column
                                                        title="Download"
                                                        key="download"
                                                        render={(_: any, record: any) => (
                                                            <Button 
                                                                type="default" 
                                                                block
                                                                onClick={async () => {
                                                                    const { data } = await axios.get(
                                                                        `${azure_function_url}/LLMModel?command=files_get&id=${record.id}&context=${context}`,
                                                                        {
                                                                            headers: {
                                                                            'Authorization': parent.bearerToken
                                                                        }});
                                    
                                                                    const file = new File([Buffer.from(data.data,"base64")], record.name, {type: 'application/octet-stream'});
                                                                    const element = document.createElement("a");
                                                                    element.href = URL.createObjectURL(file);
                                                                    element.download = record.name;
                                                                    document.body.appendChild(element);
                                                                    element.click();
                                                                }}
                                                            >
                                                                <CloudDownloadOutlined />
                                                            </Button>
                                                        )}
                                                    />
                                                </Table>
                                                <div style={{height:'250px'}}>
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                        <UploadOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag some knowledge</p>
                                                        <p className="ant-upload-hint">
                                                        Only pdf files please.
                                                        </p>
                                                    </Dragger>      
                                                </div>
                                            </>
                                    }
                                ]}/>

                            <FloatButton.Group
                                type="default"
                                style={{ right: 25, bottom: 245 }}
                            >
                                <FloatButton tooltip={<div>Permissions</div>} icon={<TeamOutlined />} onClick={()=> {setViewPermissions(viewPermissions=>!viewPermissions)}} />
                            </FloatButton.Group>
                    
                        </Card>
                    </Col>
                    <Col span={10}>
                        <Card bordered={false}>
                            <div
                                style={{
                                    height:'calc(100vh - 125px)', 
                                }}
                            >
                                <SIKE 
                                    bearerToken={parent.bearerToken} 
                                    user={parent.user} 
                                    context={context} 
                                    agent={'custom'}
                                    label={'Totalis'}
                                    thread={thread && activeQuestion ? `${thread}<->${activeQuestion}` : undefined} 
                                    permission={parent.permission}
                                    prompt={(input: string) => {return {
                                        json: [
                                            { "history": 5 },
                                            { "context": context },
                                            { "thread": thread && activeQuestion ? `${thread}<->${activeQuestion}` : undefined },
                                            { "model": "gpt-4" },
                                            {
                                                "prompt": "first",
                                                "temperature": 0,
                                                "rag": [
                                                    { 
                                                        "module": '9ac85e2c-4fc0-49e2-b350-6a0c279131f7',
                                                        "description": 
`1. "EMECO" is a RAG tool.
2. Use the "EMECO" RAG tool to search for any topic or context related to EMECO.
3. If there are more than one subject, create several queries, one query per subject. 
4. For example if the query is about A and/or B then first ask about A then B.`,
                                                    }
                                                ],
                                                "instructions": [
`You are a conversational chat agent.
A questionnaire is being filled in by the user represented by:
${JSON.stringify(formData, (key, value) => {
    if (key.startsWith('_') || value === undefined) return null;
    
    return value;
}, 4)}

---
Use the questionnaire to assist the user by responding to the question / statement in a succinct way:
${input}
`
                                                ]
                                            }
                                        ],
                                        input: input,
                                        agent_reference: 'custom'
                                    }}}
                                    initMessage={`Hello ${parent.user.data.personal.name.firstName}, I am here to assist with the assessment. How can I help you?`}  
                                />
                            </div>
                            
                        </Card>
                    </Col>
                </Row>
                
            </div>

            <Modal okType={'default'} okText={'Close'} cancelButtonProps={{ style: { display: 'none' } }} width={1000} onCancel={() => setViewPermissions(false)} onOk={() => setViewPermissions(false)}  closable={false} open={viewPermissions}>     
                <Employees user={parent.user} bearerToken={parent.bearerToken} entityId={context} />
            </Modal>
        </>
    );
}