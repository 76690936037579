// App.tsx

import React, { useState, useRef, useEffect, useReducer } from 'react';

import { Tour, FormInstance, Alert, Spin, Avatar, Select, Popconfirm, Tag, TreeSelect, Divider, Modal, Card, Tooltip, FloatButton, Input, Button, List, Form, Table, Space, Tabs, message, Row } from 'antd';
import type { TourProps } from 'antd';
import { FormOutlined, PlusCircleOutlined, MinusCircleOutlined, InfoCircleOutlined, BarsOutlined, RedoOutlined, SafetyCertificateOutlined, SearchOutlined, FilePdfOutlined, FileWordOutlined, CopyOutlined, DownOutlined, UpOutlined, SendOutlined, LoadingOutlined, DeleteOutlined} from '@ant-design/icons';

import { isMobileBrowser } from '../../utils/utils';
import { prompt_cost, completion_cost } from '../../utils/constants';

import hljs from 'highlight.js';
import { marked } from 'marked';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import axios from 'axios';

import { getPrompt, prompts, gptModel } from '../../utils/prompts';

const { TextArea } = Input;
const { Item } = Form;

const { CheckableTag } = Tag;

const { Option } = Select;

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

interface Message {
    id: string;
    author: string;
    timestamp: Date;
    runningTime?: number;
    input: string | string[];
    content: string | string[];
    data: any;
    show: boolean;
    prompt_tokens?: number;
    completion_tokens?: number;
    docsearch?: string;
    tags?: any;
    agent_reference: string;
    model: string;
    temperature: number;
    signature?: any;
    prompts?: any[];
}
const IconText = ({ icon, text, onClick, color, size }: { icon: React.FC; text?: string; onClick?: any; color?:string; size?: string }) => (
    <a style={{color: 'grey'}}>
        <Tooltip title= {text}>
            <Space onClick={onClick||undefined}>
                <span style={{ 
                    fontSize: size || '24px',
                    alignItems: 'flex-start',
                    marginBlockStart: undefined,
                    color: color
                }}>
                    {React.createElement(icon)}
                </span>
            </Space>
        </Tooltip>
    </a>
);

const uniqueObjects = (objects: any[]) => objects?.reduce((unique, obj) => {
    if (!unique.some((o:any) => o.name === obj.name)) {
      unique.push(obj);
    }
    return unique;
}, [] as any[]);

const isValidJson = (jsonString?: string): boolean => {
    try {
        if(!jsonString) return false;

        JSON.parse(jsonString);
        return true;
    } catch (e) {
          return false;
    }
};

const tryParseJson = (jsonString?: string): any => {
    try {
        if(!jsonString) return jsonString;
        
        const res = JSON.parse(jsonString);

        return res;
    } catch (e) {
          return jsonString;
    }
};
  
const MessageTree : Record<string,  Message[]> = {};

export const SIKE: React.FC<any> = (parent) => {

    const [input, setInput] = useState('');
    const [tags, setTags] = useState();
    const [agent, setAgent] = useState(parent.agent || 'chat');
    const [tagTree, setTagTree] = useState<any>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [openMessage, setOpenMessages] = useState<Message>();

    const [lastMessage, setLastMessage] = useState<string>();

    const formRef = useRef<FormInstance>(null);

    const [isLoadingChat, setLoadingChat] = useState({ state: false });

    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const messagesTopRef = useRef<HTMLDivElement | null>(null);
    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const listContainerRef = useRef<HTMLDivElement | null>(null);

    const [open, setOpen] = useState<boolean>(false);
 
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        
        setTimeout(() => {
            messageInputRef.current?.focus();    
        }, 500);

        const element = listContainerRef.current;
        if (element) {
            setTimeout(() => {
                element.scrollTop = element.scrollHeight;
                
            }, 0);
        }
    };

    const scrollToTop = () => {
        messagesTopRef.current?.scrollIntoView({ behavior: "smooth" });  
    };

    const getAllTextAreaValues = () => {
        // Retrieve all form values
        const allValues = formRef.current?.getFieldsValue();
    
        // Extract the prompts array which contains the values of the TextAreas
        const promptsArray = allValues.prompts;
    
        // Check if promptsArray is valid and has items
        if (Array.isArray(promptsArray) && promptsArray.length > 0) {
            // Map through the array and extract the 'prompt' values
            const textAreaValues = promptsArray.map(item => item.prompt);
            return textAreaValues;
        } else {
            // Return an empty array if no valid data is found
            return [];
        }
    };

    const clearAllTextAreas = () => {
        formRef.current?.setFieldsValue({ prompts: [{ prompt: '' }] });
    };
    

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
  
    useEffect(() => {
        if(!MessageTree[parent.context])
            MessageTree[parent.context] = [];

        setMessages(MessageTree[parent.context]);
        
        axios.get(
            `${azure_function_url}/LLMModel?command=history&context=${parent.context}&thread=${parent.thread}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                
                let mess: Message[] = []
                if(res.data && res.data.length > 0)
                    res.data.forEach((entry: any) => {
                        const data =  JSON.parse(entry.response);
                        mess.push({ 
                            id: entry.id,
                            author: 'User', 
                            input: tryParseJson(entry.input),
                            content: tryParseJson(entry.input),
                            timestamp: new Date(entry.prompt_timestamp),
                            data: undefined,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature,
                            prompts: entry.prompts
                        }, { 
                            id: entry.id,
                            author: 'Skynet', 
                            content: data.text ? data.text : 'error',
                            timestamp: new Date(entry.response_timestamp),
                            runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                            data: data,//'error',
                            input: tryParseJson(entry.input),
                            tags: entry.metadata,
                            docsearch: entry.docsearch,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature,
                            prompt_tokens: entry.prompt_tokens,
                            completion_tokens: entry.completion_tokens,
                            signature: entry.signature,
                            prompts: entry.prompts
                        });
                    });
                else if(parent.thread) {
                    let initMessage = parent.initMessage || `Hello ${parent.user.data.personal.name.firstName}, how can I help you?`;
                    mess.push(
                        { id: '', author: 'Skynet', content: initMessage, data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }
                    );

                    const step = 5;
                    let prevText = ''
                    let index = 0;
                    const timer = setInterval(() => {
                        if (index < initMessage.length) {

                            prevText = initMessage.substring(0, index + step);
    
                            setLastMessage(prevText);
                            index += step;
                        } else {
                            clearInterval(timer);
                            scrollToBottom();

                            if(messages.length > 0)
                                setLastMessage(undefined);
                        }
                    }, 10);
                }

                setMessages(mess);
                
        }).catch(err => {console.log(err);message.error(err.toString())});

        axios.get(
            `${azure_function_url}/LLMModel?command=tags&context=${parent.context}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                if (res.data !== null && res.data !== undefined && res.data?.length > 0)
                    setTagTree(res.data)
        }).catch(err => {console.log(err);message.error(err.toString())});

    }, [parent.context, parent.thread, parent.updateCounter]);

    useEffect(() => {
        setLastMessage(undefined);        
        const timer = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timer);
    }, [messages]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldsValue({ agent: agent });
        }
    }, [agent]);

    useEffect(() => {
        if(parent.agent) 
            setAgent(parent.agent)
    }, [parent.agent]);

    const sendPrompt = async (_input: string | string[], agent: string) => {
        const input = _input || getAllTextAreaValues();
        if (input) {
            MessageTree[parent.context] = [
                ...messages, 
                { id: '', author: 'User', content: input, input: input, data: undefined, timestamp: new Date(), show: false, agent_reference: '', model: '', temperature: 0 },
                { id: '', author: 'Skynet', content: '', data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined, prompt_tokens: 0, completion_tokens: 0 }
            ];
            setLoadingChat({ state: true});

            setInput((input) => input.length > 150 ? input.substring(0, 150) : input);
            
            setMessages(MessageTree[parent.context]);
            
            for(var i = 0; i < 1; i++){
                try{
                    setInput('');   
                    clearAllTextAreas();
                    console.log(parent.prompt)

                    console.log(parent.prompt ? parent.prompt(input[0]) : getPrompt(parent.context, parent.thread, agent, input[0], tags))

                    await axios.post(
                        `${azure_function_url}/LLMModel?command=flow`, 
                        parent.prompt ? {...parent.prompt(input[0]),async: 'true'} : {...getPrompt(parent.context, parent.thread, agent, input[0], tags),async: 'true'},
                        {
                            headers: {
                                'Authorization': parent.bearerToken
                            }
                        }
                    ).then((res:any) => {

                        const waitOneSecond = (): Promise<void> => {
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                }, 1000); // 1000 milliseconds = 1 second
                            });
                        }

                        const _id = res.data;

                        console.log("ID: ", _id)

                        const date = new Date();
                        MessageTree[parent.context] = [
                            ...messages, 
                            { id: '', author: 'User', content: input, data: undefined, timestamp: date, input: input, show: false, agent_reference: agent, model: gptModel, temperature: 0 }, 
                            { id: '', author: 'Skynet', content: '', data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: agent, model: gptModel, temperature: 0, signature: undefined }];
        
                        setMessages(MessageTree[parent.context]);
                        

                        const timer = setInterval(() => {
                            try{
                            
                                axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=first`,
                                {
                                    headers: {
                                    'Authorization': parent.bearerToken
                                    }
                                }
                                )
                                .then(data => {
                                    if(data.data.trim() !== ''){
                                        console.log(data.data)
                                        // setLoadingChat({ state: false });
                                        // forceUpdate();
                                        setLastMessage(data.data);
                                        scrollToBottom();
                                        
                                    }
                                })
                                .catch(async err => {
                                    clearInterval(timer);

                                    await waitOneSecond();
                                    // axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`)
                                    // .then(data => {
                                    //     if(data.data?.prompts){
                                    //         const prmpt = data.data.prompts[0];
                                    //         MessageTree[parent.context] = [
                                    //             ...messages.filter(x=>x.id != ''), 
                                    //             { id: _id, author: 'User', content: input, input: input, data: undefined, timestamp: new Date(prmpt.start), show: false, agent_reference: agent, model: gptModel, temperature: 0 }, 
                                    //             { id: _id, author: 'Skynet', content: prmpt.output, data: prmpt, input: input, runningTime: new Date(prmpt.finish).getTime() - new Date(prmpt.start).getTime() , timestamp: new Date(prmpt.finish), show: false, tags: [], docsearch: res.data.docsearch, agent_reference: agent, model: gptModel, temperature: 0, signature: undefined, prompt_tokens: prmpt.tokens.prompt, completion_tokens: prmpt.tokens.completion }];

                                    //         setMessages(MessageTree[parent.context]);
                                    //         setLoadingChat({ state: false});
                                    //         forceUpdate();

                                    //         console.log(data)
                                    //     }
                                    // })
                                    // .catch(err=>{console.log(err)})

                                    axios.get(
                                        `${azure_function_url}/LLMModel?command=history&context=${parent.context}&thread=${parent.thread}`, 
                                        {
                                            headers: {
                                            'Authorization': parent.bearerToken
                                            }
                                        }).then(res => {
                                            
                                            let mess: Message[] = []
                                            if(res.data && res.data.length > 0)
                                                res.data.forEach((entry: any) => {
                                                    const data =  JSON.parse(entry.response);
                                                    mess.push({ 
                                                        id: entry.id,
                                                        author: 'User', 
                                                        input: tryParseJson(entry.input),
                                                        content: tryParseJson(entry.input),
                                                        timestamp: new Date(entry.prompt_timestamp),
                                                        data: undefined,
                                                        show: false,
                                                        agent_reference: entry.agent_reference,
                                                        model: entry.model,
                                                        temperature: entry.temperature,
                                                        prompts: entry.prompts
                                                    }, { 
                                                        id: entry.id,
                                                        author: 'Skynet', 
                                                        content: data.text ? data.text : 'error',
                                                        timestamp: new Date(entry.response_timestamp),
                                                        runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                        data: data,//'error',
                                                        input: tryParseJson(entry.input),
                                                        tags: entry.metadata,
                                                        docsearch: entry.docsearch,
                                                        show: false,
                                                        agent_reference: entry.agent_reference,
                                                        model: entry.model,
                                                        temperature: entry.temperature,
                                                        prompt_tokens: entry.prompt_tokens,
                                                        completion_tokens: entry.completion_tokens,
                                                        signature: entry.signature,
                                                        prompts: entry.prompts
                                                    });
                                                });
                                            else if(parent.thread) {
                                                let initMessage = parent.initMessage || `Hello ${parent.user.data.personal.name.firstName}, how can I help you?`;
                                                mess.push(
                                                    { id: '', author: 'Skynet', content: initMessage, data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }
                                                );
                            
                                                const step = 5;
                                                let prevText = ''
                                                let index = 0;
                                                const timer = setInterval(() => {
                                                    if (index < initMessage.length) {
                            
                                                        prevText = initMessage.substring(0, index + step);
                                
                                                        setLastMessage(prevText);
                                                        index += step;
                                                    } else {
                                                        clearInterval(timer);
                                                        scrollToBottom();
                            
                                                        if(messages.length > 0)
                                                            setLastMessage(undefined);
                                                    }
                                                }, 10);
                                            }
                            
                                            setMessages(mess);
                                            setLoadingChat({ state: false});
                                            forceUpdate();
                                            
                                    }).catch(err => {console.log(err);message.error(err.toString())});
                                });
                            }
                            catch{
                                clearInterval(timer);
                                waitOneSecond().then(() => {
                                // axios.get(`${azure_function_url}/LLMModel?command=flow_get&id=${_id}`)
                                //     .then(data => {
                                //         console.log(data)
                                //         if(data.data?.prompts){
                                //             console.log(data);
                                //             const prmpt = data.data.prompts[0];
                                //             MessageTree[parent.context] = [
                                //                 ...messages.filter(x=>x.id != ''), 
                                //                 { id: _id, author: 'User', content: input, input: input, data: undefined, timestamp: new Date(prmpt.start), show: false, agent_reference: agent, model: gptModel, temperature: 0 }, 
                                //                 { id: _id, author: 'Skynet', content: prmpt.output, data: prmpt, input: input, runningTime: new Date(prmpt.finish).getTime() - new Date(prmpt.start).getTime() , timestamp: new Date(prmpt.finish), show: false, tags: [], docsearch: res.data.docsearch, agent_reference: agent, model: gptModel, temperature: 0, signature: undefined, prompt_tokens: prmpt.tokens.prompt, completion_tokens: prmpt.tokens.completion }];

                                //             setMessages(MessageTree[parent.context]);
                                //             setLoadingChat({ state: false});
                                //             forceUpdate();
                                //         }
                                //     })
                                axios.get(
                                    `${azure_function_url}/LLMModel?command=history&context=${parent.context}&thread=${parent.thread}`, 
                                    {
                                        headers: {
                                        'Authorization': parent.bearerToken
                                        }
                                    }).then(res => {
                                        
                                        let mess: Message[] = []
                                        if(res.data && res.data.length > 0)
                                            res.data.forEach((entry: any) => {
                                                const data =  JSON.parse(entry.response);
                                                mess.push({ 
                                                    id: entry.id,
                                                    author: 'User', 
                                                    input: tryParseJson(entry.input),
                                                    content: tryParseJson(entry.input),
                                                    timestamp: new Date(entry.prompt_timestamp),
                                                    data: undefined,
                                                    show: false,
                                                    agent_reference: entry.agent_reference,
                                                    model: entry.model,
                                                    temperature: entry.temperature,
                                                    prompts: entry.prompts
                                                }, { 
                                                    id: entry.id,
                                                    author: 'Skynet', 
                                                    content: data.text ? data.text : 'error',
                                                    timestamp: new Date(entry.response_timestamp),
                                                    runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                    data: data,//'error',
                                                    input: tryParseJson(entry.input),
                                                    tags: entry.metadata,
                                                    docsearch: entry.docsearch,
                                                    show: false,
                                                    agent_reference: entry.agent_reference,
                                                    model: entry.model,
                                                    temperature: entry.temperature,
                                                    prompt_tokens: entry.prompt_tokens,
                                                    completion_tokens: entry.completion_tokens,
                                                    signature: entry.signature,
                                                    prompts: entry.prompts
                                                });
                                            });
                                        else if(parent.thread) {
                                            let initMessage = parent.initMessage || `Hello ${parent.user.data.personal.name.firstName}, how can I help you?`;
                                            mess.push(
                                                { id: '', author: 'Skynet', content: initMessage, data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }
                                            );
                        
                                            const step = 5;
                                            let prevText = ''
                                            let index = 0;
                                            const timer = setInterval(() => {
                                                if (index < initMessage.length) {
                        
                                                    prevText = initMessage.substring(0, index + step);
                            
                                                    setLastMessage(prevText);
                                                    index += step;
                                                } else {
                                                    clearInterval(timer);
                                                    scrollToBottom();
                        
                                                    if(messages.length > 0)
                                                        setLastMessage(undefined);
                                                }
                                            }, 10);
                                        }
                        
                                        setMessages(mess);

                                        setLoadingChat({ state: false});
                                        forceUpdate();
                                        
                                }).catch(err => {console.log(err);message.error(err.toString())});
                                });
                            }

                        }, 250);








                        // MessageTree[parent.context] = [
                        //     ...messages, 
                        //     { id: res.data.id, author: 'User', content: input, input: input, data: undefined, timestamp: new Date(res.data.prompt_timestamp), show: false, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature }, 
                        //     { id: res.data.id, author: 'Skynet', content: res.data.text, data: res.data, input: input, runningTime: new Date(res.data.response_timestamp).getTime() - new Date(res.data.prompt_timestamp).getTime() , timestamp: new Date(res.data.response_timestamp), show: false, tags: res.data.metadata, docsearch: res.data.docsearch, agent_reference: res.data.agent_reference, model: res.data.model, temperature: res.data.temperature, signature: res.data.signature, prompt_tokens: res.data.prompt_tokens, completion_tokens: res.data.completion_tokens }];

                        // setMessages(MessageTree[parent.context]);
                        
                        // let prevText = '';

                        // const step = 10;

                        // let index = 0;
                        // const timer = setInterval(() => {
                        //     if (index < res.data.text.length) {

                        //         prevText = res.data.text.substring(0, index + step);
        
                        //         setLastMessage(prevText);
                        //         index += step;
                        //     } else {
                        //         clearInterval(timer);
                        //         scrollToBottom();
                        //     }
                        // }, 10);

                        // setLoadingChat({ state: false});
                        // forceUpdate();
                    }).catch(err => {
                        console.log(err);
                        message.error(err.toString());

                        const date = new Date();
                        MessageTree[parent.context] = [
                            ...messages, 
                            { id: '', author: 'User', content: input, data: undefined, timestamp: date, input: input, show: false, agent_reference: '', model: '', temperature: 0 }, 
                            { id: '', author: 'Skynet', content: err.toString(), data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }];
        
                        setMessages(MessageTree[parent.context]);
                        setLoadingChat({ state: false});
                        forceUpdate();
                    });
        
                    setInput('');   
                    clearAllTextAreas();
                    return;
                }
                catch(err){
                    console.log('---err---', err)
                }
                message.warning(`resending your message. (${i+1}/5)`);
                await new Promise(f => setTimeout(f, 1000));
                setLoadingChat({ state: false});
            }
        }
    };

    const handleMessageSend = async () => {
        sendPrompt(input, agent);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        const textarea = event.currentTarget;

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevent creating a new line
            handleMessageSend();
        }
        else if (event.key === 'Tab') {
            event.preventDefault();

            const spaces = "    "; // 4 spaces for a tab, you can adjust this
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = textarea.value.substring(start, end);

            if (start !== end) {
                // Text is selected. Indent each line of the selected text.
                const indentedText = selectedText.split('\n').map(line => spaces + line).join('\n');
                textarea.value = textarea.value.substring(0, start)
                    + indentedText
                    + textarea.value.substring(end);
                
                // Adjust the selection to highlight the newly indented text
                textarea.selectionStart = start;
                textarea.selectionEnd = start + indentedText.length;
            } else {
                // No text selected. Insert spaces for a tab.
                textarea.value = textarea.value.substring(0, start)
                    + spaces
                    + textarea.value.substring(end);

                // Position the caret after the inserted spaces
                textarea.selectionStart = textarea.selectionEnd = start + spaces.length;
            }
        }
    };

    const ref_input = useRef(null);
    const ref_agent = useRef(null);
    const ref_send = useRef(null);
    const ref_messages = useRef(null);
    const ref_microsig = useRef(null);
    const ref_sources = useRef(null);
    const ref_redo = useRef(null);
    const ref_copy = useRef(null);
    

    const steps: TourProps['steps'] = [
        {
          title: 'Input Prompt',
          description: 'Type your prompt with a question or statement.',
          cover: (
            <img
              alt="tour.png"
              src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
            />
          ),
          target: () => ref_input.current,
        },
        {
          title: 'Persona',
          description: 'Save your changes.',
          target: () => ref_agent.current,
        },
        {
          title: 'Send',
          description: 'Click to see other actions.',
          target: () => ref_send.current,
        },
        {
          title: 'Messages',
          description: 'Click to see other actions.',
          target: () => ref_messages.current,
        },
        {
          title: 'MicroSignatures',
          description: 'Click to see other actions.',
          target: () => ref_microsig.current,
        },
        {
          title: 'Sources',
          description: 'Click to see other actions.',
          target: () => ref_sources.current,
        },
        {
          title: 'Regenerate',
          description: 'Click to see other actions.',
          target: () => ref_redo.current,
        },
        {
          title: 'Copy',
          description: 'Click to see other actions.',
          target: () => ref_copy.current,
        }
    ];
    
           
    return (
        <>
            <div ref={listContainerRef} style={{ position: 'relative', height:'100%', width: '100%' }}>
                <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
                <List
                    style={{ 
                        width:'100%',
                        height: 'calc(100% - 100px)',
                        overflow: 'auto',
                        position: 'absolute',

                        flexGrow: 1
                    }}
                    itemLayout="vertical"
                    loading={isLoadingChat.state}
                    dataSource={messages.map((x,i)=>{return {...x, key:i}})}
                    renderItem={(_message, i) => (
                        <>
                            <br></br>
                            <List.Item 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row', // Horizontal layout
                                    borderBottom: i === messages.length - 1 ? 'none' : '1px solid black',
                                    alignItems: 'flex-start' // Align items at the start of the flex container
                                }}
                            >
                                <div style={{ flexGrow: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                        <List.Item.Meta 
                                            avatar={ _message.author == 'User' ? <Avatar src={parent.user.avatar} /> : <Avatar src="sike_logo.png" /> }
                                            title={
                                                <span style={{ fontSize: '20px' }}>
                                                    {_message.author == 'User' ? parent.user.data.personal.name.firstName : prompts[_message.agent_reference] ? `${parent.label || 'SIKE'} | ` + prompts[_message.agent_reference].label : `${parent.label || 'SIKE'}`}
                                                </span>
                                            }
                                            description={`${_message.runningTime ? 'Running Time: ' + (_message.runningTime / 1000).toLocaleString() + ' sec' : _message.timestamp.toLocaleString()}` + (messages?.length > 1 && _message.author !== 'User' ? ` | Token Spend: AUD ${_message.prompt_tokens && _message.completion_tokens ? (_message.prompt_tokens * prompt_cost + _message.completion_tokens * completion_cost).toFixed(2) : 0}` : '')} 
                                        />
                                        <Space>
                                        {
                                            (
                                            _message.author == 'User' ? 
                                            [
                                                <Popconfirm
                                                    title="Delete Message"
                                                    description="Would you like to delete the message?"
                                                    onConfirm={() => { 
                                                        axios.get(
                                                            `${azure_function_url}/LLMModel?command=history_delete&context=${parent.context}&thread=${parent.thread}&id=${_message.id}`, 
                                                            {
                                                                headers: {
                                                                'Authorization': parent.bearerToken
                                                                }
                                                            }).then(res => {
                                                                let mess: Message[] = []
                                                                if(res.data.forEach)
                                                                    res.data.forEach((entry: any) => {
                                                                        const data =  JSON.parse(entry.response);
                                                                        mess.push({ 
                                                                            id: entry.id,
                                                                            author: 'User', 
                                                                            input: tryParseJson(entry.input),
                                                                            content: tryParseJson(entry.input),
                                                                            timestamp: new Date(entry.prompt_timestamp),
                                                                            data: undefined,
                                                                            show: false,
                                                                            agent_reference: entry.agent_reference,
                                                                            model: entry.model,
                                                                            temperature: entry.temperature,
                                                                            prompts: entry.prompts
                                                                        }, { 
                                                                            id: entry.id,
                                                                            author: 'Skynet', 
                                                                            content: data.text ? data.text : 'error',
                                                                            timestamp: new Date(entry.response_timestamp),
                                                                            runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                                            data: data,//'error',
                                                                            input: tryParseJson(entry.input),
                                                                            tags: entry.metadata,
                                                                            docsearch: entry.docsearch,
                                                                            show: false,
                                                                            agent_reference: entry.agent_reference,
                                                                            model: entry.model,
                                                                            temperature: entry.temperature,
                                                                            prompt_tokens: entry.prompt_tokens,
                                                                            completion_tokens: entry.completion_tokens,
                                                                            signature: entry.signature,
                                                                            prompts: entry.prompts
                                                                        });
                                                                    })
                                                                
                                                                message.success('Message deleted');
                                                                setMessages(mess);
                                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                                        }
                                                    } 
                                                    onCancel={() => { }}
                                                    okText="Delete"
                                                    cancelText="Cancel"
                                                >
                                                    <IconText 
                                                        icon={DeleteOutlined} 
                                                    />
                                                </Popconfirm>,
                                                <div style={{
                                                    borderLeft: '1px solid #ddd',
                                                    height: '20px', // Set a fixed height for the divider
                                                    alignSelf: 'center', // Align the divider to center vertically
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }} />,
                                                <div ref={ref_redo}>
                                                    <IconText 
                                                        icon={FormOutlined} 
                                                        text={'Move to Prompt'} 
                                                        onClick={() => { 
                                                            setAgent(_message.agent_reference);

                                                            if(typeof _message.input === 'string')
                                                                formRef.current?.setFieldsValue({ prompts: [ { prompt: _message.input.trim() } ] });
                                                            else
                                                                formRef.current?.setFieldsValue({ prompts: _message.input.map(x => { return { prompt: x.trim() } }) });

                                                            messageInputRef.current?.focus();
                                                            forceUpdate();
                                                        }} 
                                                    />
                                                </div>,
                                                <div style={{
                                                    borderLeft: '1px solid #ddd',
                                                    height: '20px', // Set a fixed height for the divider
                                                    alignSelf: 'center', // Align the divider to center vertically
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }} />,
                                                <IconText 
                                                    icon={CopyOutlined} 
                                                    text={'Copy'} 
                                                    onClick={() => { 
                                                            if(Array.isArray(_message.content))
                                                                navigator.clipboard.writeText(_message.content.join('\n'));
                                                            else
                                                                navigator.clipboard.writeText(_message.content);
                                                            message.success(`Copied!`);
                                                        }
                                                    } 
                                                />
                                            ].map((x, i)=> { return {...x, key: i} })
                                            : 
                                            [
                                                <Popconfirm
                                                    title="Microsignature"
                                                    description="Would you like to microsign this message?"
                                                    onConfirm={() => { 
                                                        console.log(_message)
                                                            axios.post(
                                                                `${azure_function_url}/NCID?command=signature_request`,
                                                                {
                                                                    document: {
                                                                        id: _message.id,
                                                                        version: 1,
                                                                        type: 'sike_microsignature',

                                                                        model: _message.model,
                                                                        tags: _message.tags,
                                                                        temperature: _message.temperature,
                                                                        agent_reference: _message.agent_reference,
                                                                        sources:  _message.data ? uniqueObjects(_message.data.sourceDocuments.map((x:any)=> { return {name: x?.metadata?.name, type: x?.metadata?.type, id: x?.metadata?.id }}).flat()) : undefined
                                                                    },
                                                                },
                                                                {
                                                                    maxContentLength: Number.POSITIVE_INFINITY,
                                                                    headers: {
                                                                        'Authorization': parent.bearerToken
                                                                    }
                                                                }
                                                            ).then(x => { 
                                                                console.log(x)
                                                                
                                                                axios.post(
                                                                    `${azure_function_url}/LLMModel?command=add_signature&context=${parent.context}&thread=${parent.thread}&id=${_message.id}`,
                                                                    {
                                                                        signature: x.data
                                                                    },
                                                                    {
                                                                        headers: {
                                                                            'Authorization': parent.bearerToken
                                                                        },
                                                                        
                                                                    }
                                                                ).then(res => {
                                                                    console.log(res)
                                                                    let mess: Message[] = []
                                                                    if(res.data.forEach)
                                                                        res.data.forEach((entry: any) => {
                                                                            const data =  JSON.parse(entry.response);
                                                                            mess.push({ 
                                                                                id: entry.id,
                                                                                author: 'User', 
                                                                                content: entry.input,
                                                                                input: entry.input,
                                                                                timestamp: new Date(entry.prompt_timestamp),
                                                                                data: undefined,
                                                                                show: false,
                                                                                agent_reference: entry.agent_reference,
                                                                                model: entry.model,
                                                                                temperature: entry.temperature
                                                                            }, { 
                                                                                id: entry.id,
                                                                                author: 'Skynet', 
                                                                                content: data.text ? data.text : data,
                                                                                timestamp: new Date(entry.response_timestamp),
                                                                                runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                                                data: data,
                                                                                input: input,
                                                                                tags: entry.metadata,
                                                                                docsearch: entry.docsearch,
                                                                                show: false,
                                                                                prompt_tokens: entry.prompt_tokens,
                                                                                completion_tokens: entry.completion_tokens,
                                                                                agent_reference: entry.agent_reference,
                                                                                model: entry.model,
                                                                                temperature: entry.temperature,
                                                                                signature: entry.signature
                                                                            });
                                                                        })
                                                                    
                                                                    setMessages(mess);
                                                                    message.success('successfully signed');
                                                                });
                                                            }).catch(err => {console.log(err);message.error(err.toString())});
                                                        }
                                                    } 
                                                    onCancel={() => { }}
                                                    okText="Sign"
                                                    cancelText="Cancel"
                                                >
                                                    <div ref={ref_microsig}>
                                                        <IconText 
                                                            icon={SafetyCertificateOutlined}
                                                            color={_message.signature ? '#52c41a' : undefined} 
                                                            text={'Microsign'}
                                                        />
                                                    </div>
                                                </Popconfirm>,
                                                <div style={{
                                                    borderLeft: '1px solid #ddd',
                                                    height: '20px', // Set a fixed height for the divider
                                                    alignSelf: 'center', // Align the divider to center vertically
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }} />,
                                                <div ref={ref_sources}>
                                                    <IconText 
                                                        icon={BarsOutlined} 
                                                        text={'Sources'}
                                                        onClick={() => {
                                                            _message.show = true;
                                                            setOpenMessages(_message)
                                                            }
                                                        }
                                                    />
                                                </div>,
                                                <div style={{
                                                    borderLeft: '1px solid #ddd',
                                                    height: '20px', // Set a fixed height for the divider
                                                    alignSelf: 'center', // Align the divider to center vertically
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }} />
                                                ,
                                                <div ref={ref_redo}>
                                                    <IconText 
                                                        icon={FormOutlined} 
                                                        text={'Move Response to Prompt'} 
                                                        onClick={() => { 
                                                            setAgent(_message.agent_reference);
                                                            formRef.current?.setFieldsValue({ prompts: [ { prompt: typeof _message.content === 'object' ? JSON.stringify(_message.content).toString().trim() : _message.content.toString().trim() } ] });
                                                            messageInputRef.current?.focus();
                                                            forceUpdate();
                                                        }} 
                                                    />
                                                </div>,
                                                <div style={{
                                                    borderLeft: '1px solid #ddd',
                                                    height: '20px', // Set a fixed height for the divider
                                                    alignSelf: 'center', // Align the divider to center vertically
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }} />,
                                                <div ref={ref_copy}>
                                                <IconText 
                                                    icon={CopyOutlined} 
                                                    text={'Copy'} 
                                                    onClick={() => { 
                                                        navigator.clipboard.writeText(_message.content.toString());
                                                        message.success(`Copied!`);
                                                        }
                                                    } 
                                                />
                                                </div>
                                            ].map((x, i)=> { return {...x, key: i} })
                                            )
                                        }
                                        </Space>
                                    </div>
                                    {
                                        _message.content !== '' || lastMessage?
                                        <>
                                        {0 === i && (<div ref={messagesTopRef} />)}
                                        {
                                        ( _message.author == 'User' && Array.isArray(_message.prompts) && _message.prompts.length > 1)? 
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={_message.prompts}
                                            renderItem={(item) => 
                                                <List.Item
                                                    // actions={[
                                                    //     <IconText
                                                    //         icon={CopyOutlined}
                                                    //         text={'Copy'} 
                                                    //         size={'18px'}
                                                    //         onClick={() => { 
                                                    //             navigator.clipboard.writeText(item.toString());
                                                    //             message.success(`Copied!`);
                                                    //         }} 
                                                    //     />
                                                    // ]}
                                                >
                                                    <div style={{ flexGrow: 1 }}>
                                                    <List.Item.Meta 
                                                        title={
                                                            <span style={{ fontSize: '20px' }}>
                                                                {item.prompt}
                                                            </span>
                                                        }
                                                        description={`${'Running Time: ' + ((new Date(item.finish).getTime() -  new Date(item.start).getTime())/ 1000).toLocaleString() + ' sec'}` + ` | Token Spend: AUD ${(item.tokens.prompt * prompt_cost + item.tokens.completion * completion_cost).toFixed(2)}`} 
                                                    />
                                                    {/* <br></br> */}
                                                    <JsonView collapsed={1} src={item} />    
                                                    {/* {item && (<div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(((typeof item.instructions.content === 'string') ||  (Array.isArray(item.instructions) && (item.instructions as any).every((element: any) => typeof element === 'string'))) ? item.instructions.toString() : JSON.stringify(item.instructions)) }} />)} */}
                                                    {/* {JSON.stringify(item)} */}
                                                    </div>
                                                </List.Item>
                                            }
                                        />
                                        : _message.author == 'User' && Array.isArray(_message.input) && _message.input.length > 1 ? 
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={_message.input}
                                            renderItem={(item) => 
                                                <List.Item
                                                    // actions={[
                                                    //     <IconText
                                                    //         icon={CopyOutlined}
                                                    //         text={'Copy'} 
                                                    //         size={'18px'}
                                                    //         onClick={() => { 
                                                    //             navigator.clipboard.writeText(item.toString());
                                                    //             message.success(`Copied!`);
                                                    //         }} 
                                                    //     />
                                                    // ]}
                                                >
                                                    <div style={{ flexGrow: 1 }}>
                                                    {/* <List.Item.Meta 
                                                        title={
                                                            <span style={{ fontSize: '20px' }}>
                                                                {item.prompt}
                                                            </span>
                                                        }
                                                        // description={`${'Running Time: ' + ((new Date(item.finish).getTime() -  new Date(item.start).getTime())/ 1000).toLocaleString() + ' sec'}` + ` | Cost: AUD ${(item.tokens.prompt * prompt_cost + item.tokens.completion * completion_cost).toFixed(2)}`} 
                                                    /> */}
                                                    {/* <br></br> */}
                                                    <JsonView collapsed={1} src={item} />    
                                                    {/* {item && (<div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(((typeof item.instructions.content === 'string') ||  (Array.isArray(item.instructions) && (item.instructions as any).every((element: any) => typeof element === 'string'))) ? item.instructions.toString() : JSON.stringify(item.instructions)) }} />)} */}
                                                    {/* {JSON.stringify(item)} */}
                                                    </div>
                                                </List.Item>
                                            }
                                        />
                                        :
                                        <div ref={ref_messages} style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(messages.length - 1 === i && lastMessage ? lastMessage : ((typeof _message.content === 'string') ||  (Array.isArray(_message.content) && (_message.content as any).every((element: any) => typeof element === 'string'))) ? _message.content.toString() : JSON.stringify(_message.content)) }} />
                                        }
                                        {messages.length - 1 === i && (<div ref={messagesEndRef} />)}
                                        </>
                                    :
                                        <>
                                        <Spin tip="Loading...">
                                            <Alert
                                                message="Working on it..."
                                                description="I need a second to work my magic!"
                                                type="info"
                                            />
                                        </Spin>
                                        {messages.length - 1 === i && (<div ref={messagesEndRef} />)}
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        </>
                                    }
                                    <br></br>
                                    { 
                                        _message.data?.sourceDocuments?.length > 0 && _message.tags ?
                                            <>
                                                <Space size={[0, 8]} >
                                                { 
                                                    Array.from(new Set(_message.data.sourceDocuments.map((x:any)=>x.metadata.tags).flat())).map((x:any)=><CheckableTag style={_message.tags?.includes(x) ? undefined : { backgroundColor: '#f0f0f0', borderColor: '#d9d9d9', color: '#595959' }}
                                                    key={x} checked={_message.tags?.includes(x)}>{x}</CheckableTag>)
                                                }
                                                </Space>
                                                <br></br>
                                                <br></br>
                                            </>
                                        :
                                        <></>
                                    }
                                    { 
                                        _message.data && _message.data.sourceDocuments ?
                                        <>
                                            { _message.docsearch ? (isValidJson(_message.docsearch) ? JSON.parse(_message.docsearch).map((x:string, i:number)=> <Tag key={i} icon={<SearchOutlined />}>{x}</Tag>) : <Tag icon={<SearchOutlined />}>{_message.docsearch}</Tag>) : <></>}
                                            <br></br>
                                            <br></br>
                                            <Space size={[0, 8]} wrap>
                                            { 
                                                uniqueObjects(_message.data.sourceDocuments?.map((x:any)=> { return { name: x?.metadata?.name, tags: x?.metadata?.tags, id: x?.metadata?.uuid }}).flat())
                                                .filter((x:any)=>x.name)
                                                .map((x:any, i:number)=>
                                                    <a
                                                        key={i}
                                                        onClick={async () => {
                                                            try{
                                                                console.log(x)
                                                            const name = x.name+"."+x.type;
                                                            const { data } = await axios.get(
                                                                `${azure_function_url}/LLMModel?command=vectorised_files_get&id=${x.id}`,
                                                                {
                                                                    headers: {
                                                                    'Authorization': parent.bearerToken
                                                                }})

                                                            const file = new File([Buffer.from(data.data,"base64")], name, {type: 'application/octet-stream'});
                                                            const element = document.createElement("a");
                                                            element.href = URL.createObjectURL(file);
                                                            element.download = name;
                                                            document.body.appendChild(element);
                                                            element.click();

                                                            }catch(err:any){{console.log(err);message.error(err.toString())}};
                                                        }}
                                                    >
                                                        <Tag icon={x.tags[0].endsWith(".pdf") ? <FilePdfOutlined />:<FileWordOutlined />} color="success">
                                                            {x.name}
                                                        </Tag>
                                                    </a>
                                                )
                                            }
                                            </Space>
                                            <br></br>
                                            <br></br>
                                        </>
                                        :
                                        <></>
                                    }
                                    
                                </div>    
                            </List.Item>
                                
                        </>
                    )}
                />
                
                <div style={{ 
                    display: 'flex', 
                    
                    flexDirection: 'column', // Added for vertical alignment
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
    
                    width: '100%',
                    boxSizing: 'border-box',
                    backgroundColor: 'white',
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.75)', 

                    position: 'absolute',
                    bottom: '0px',
                }}>
                    <Form 
                        ref={formRef} 
                        style={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            margin: '10px' 
                        }} 
                        initialValues={{prompts:[{prompt:''}]}}
                    >
                        <Button 
                            type="primary" 
                            icon={(isLoadingChat.state ? <LoadingOutlined />:<></>)} 
                            style={{ 
                                color: 'rgba(0, 0, 0, 0.75)',
                                backgroundColor: 'transparent', 
                                borderColor: 'transparent', 
                                borderRadius: '0 0 0 0',
                                alignSelf: 'flex-end',
                                marginBottom: '0px',
                                boxShadow:'none'
                            }}
                        />
                        
                        <Item style={{ flexGrow: 1, marginBottom: 0, marginRight: '-1px' }}>
                            <div ref={ref_input} style={{ width: '100%' }}>
                                <Form.List name="prompts">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div key={key} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    { agent === 'beta' && (key > 0 ? (<MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: '12px', color: 'red' }} />) : (<PlusCircleOutlined onClick={() => add()} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: fields.length === 1 ? undefined : '12px' }} />))}
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'prompt']}
                                                        style={{ flex: 1, marginBottom: fields.length === 1 ? 0 : undefined, maxHeight: '400px', overflow: 'auto' }}
                                                    >
                                                        <TextArea 
                                                            ref={messageInputRef}
                                                            disabled={isLoadingChat.state}
                                                            placeholder="How can I help you?" 
                                                            onKeyDown={handleKeyDown}
                                                            autoSize={{ minRows: 1 }}
                                                            bordered={false}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </Item>
                        
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Space.Compact 
                                style={{ 
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexGrow: 1,
                                    marginBottom: 0,
                                    marginRight: '-1px',
                                }}
                            >
                                <div ref={ref_agent}>
                                { parent.prompt === undefined && (<Item name={'agent'} noStyle>
                                    <Select 
                                        style={{ 
                                            width: '150px'
                                        }} 
                                        value={agent}
                                        onChange={(value) => { setAgent(value) }}  
                                        placeholder="Select persona">
                                        { Object.keys(prompts).map((key) => <Option key={key} value={key}>{prompts[key].label}</Option> )}
                                    </Select>                                
                                </Item>)}
                                </div>
                                { parent.prompt === undefined && tagTree && tagTree.length > 0 && (
                                <Item name={'tags'} noStyle>
                                    <TreeSelect
                                        style={{ 
                                            width: '200px',
                                        }} 
                                        dropdownStyle={{ 
                                            minWidth: '450px', // Ensure that the dropdown is at least as wide as the input
                                            maxWidth: '50%', // Example to ensure it doesn't exceed the viewport width, adjust as necessary
                                        }}
                                        placeholder={'Select tags...'}
                                        multiple={true}
                                        onChange={e => setTags(e)} 
                                        treeData={tagTree}
                                    />  
                                </Item>
                                )}
                                
                            </Space.Compact>
                            <Button
                                ref={ref_send}
                                type="primary" 
                                icon={<SendOutlined />} 
                                onClick={handleMessageSend}
                                style={{ 
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    backgroundColor: 'transparent', 
                                }}
                            />
                        </div>
                    </Form>
                </div>
                
                <FloatButton.Group
                    type="default"
                    style={{ right: 25, bottom: 75 }}
                >
                    <Popconfirm
                        title="Delete All Messages"
                        description="Would you like to delete all the messages?"
                        onConfirm={() => { 
                            axios.get(
                                `${azure_function_url}/LLMModel?command=history_delete_all&context=${parent.context}&thread=${parent.thread}`, 
                                {
                                    headers: {
                                    'Authorization': parent.bearerToken
                                    }
                                }).then(res => {
                                    let mess: Message[] = []
                                    if(res.data.forEach)
                                        res.data.forEach((entry: any) => {
                                            const data =  JSON.parse(entry.response);
                                            mess.push({ 
                                                id: entry.id,
                                                author: 'User', 
                                                input: tryParseJson(entry.input),
                                                content: tryParseJson(entry.input),
                                                timestamp: new Date(entry.prompt_timestamp),
                                                data: undefined,
                                                show: false,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature,
                                                prompts: entry.prompts
                                            }, { 
                                                id: entry.id,
                                                author: 'Skynet', 
                                                content: data.text ? data.text : 'error',
                                                timestamp: new Date(entry.response_timestamp),
                                                runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                data: data,//'error',
                                                input: tryParseJson(entry.input),
                                                tags: entry.metadata,
                                                docsearch: entry.docsearch,
                                                show: false,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature,
                                                prompt_tokens: entry.prompt_tokens,
                                                completion_tokens: entry.completion_tokens,
                                                signature: entry.signature,
                                                prompts: entry.prompts
                                            });
                                        })
                                    
                                    message.success('Messages deleted');
                                    setMessages(mess);
                            }).catch(err => {console.log(err);message.error(err.toString())});
                            }
                        } 
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <FloatButton tooltip={<div>Clear Chat History</div>} icon={<DeleteOutlined />} onClick={()=> {}} />
                    </Popconfirm>
                    <FloatButton tooltip={<div>Scroll Up</div>} icon={<UpOutlined />} onClick={()=> {scrollToTop();}} />
                    <FloatButton tooltip={<div>Scroll Down</div>} icon={<DownOutlined />} onClick={()=> {scrollToBottom();}} />
                    <FloatButton tooltip={<div>Tour</div>} icon={<InfoCircleOutlined />} onClick={()=> {setOpen(true);}} />
                </FloatButton.Group>
            </div>
          
            <Modal okType={'default'} okText={'Close'} cancelButtonProps={{ style: { display: 'none' } }} title="View Sources" width={1000} onCancel={() => setOpenMessages(undefined)} onOk={() => setOpenMessages(undefined)}  closable={true} open={openMessage !== undefined}>
                <Card bordered={true} style={{ width: '100%' }}>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(openMessage ? openMessage.content.toString() : '') }} />

                    {openMessage?.data.sourceDocuments.length > 0 ?
                    <>
                    <Divider orientation="left" plain>Sources</Divider>
                    <List
                        style={{ 
                            width: '100%', 
                            margin: '0 auto'
                        }}
                        itemLayout="vertical"
                        dataSource={openMessage?.data.sourceDocuments.map((x:any,i:number)=>{return {...x, key:i}})}
                        renderItem={(_message:any) => 
                            <List.Item>
                                
                                <div style={{ paddingLeft: '40px', paddingRight: '40px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(_message.pageContent) }} />
                                <br></br>
                                <br></br>
                                <JsonView collapsed={1} src={_message.metadata} />    
                            </List.Item>
                        }
                    />
                    </>
                    :<></>
                    }
                    
                </Card>
            </Modal>
        </>
    );
}