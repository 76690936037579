import React from 'react';
import { Input, Button, Form, Space, message } from 'antd';
import axios from 'axios';

import { UserUI } from '../../utils/data_types';

export interface INewEmployeeProps {
    user?: UserUI;
    bearerToken: string;
    entityId?: string;
    done: () => void;
}
export const NewEmployee: React.FC<INewEmployeeProps> = (parent) => {
    const [form] = Form.useForm();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const onFinish = (values: any) => {
        
        axios.post(
            `${azure_function_url}/NCID?command=employees_invite`,
            {
                entityid: parent.entityId || parent.user?.entity?.id,
                employeeid: values.email
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            message.success('Invitation sent')
            parent.done();
        });
    };

    return (        
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            form={form}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item
                name={'email'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Member email is necessary' }]}
            >
                <Input style={{width:'100%'}} placeholder="Member email" />
            </Form.Item>
            
            
            <Form.Item>
                <Space size="small">
                    <Button onClick={() => { parent.done() }}  style={{ textAlign: 'right' }}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}