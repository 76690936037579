import React, { useState, useEffect } from 'react';
import { Select, Space, Table, Popconfirm, Card, Button, message } from 'antd';

import { UserAddOutlined, LoadingOutlined, EyeOutlined, DeleteOutlined, UndoOutlined, StopOutlined } from '@ant-design/icons';

import { NewEmployee } from './NewEmployee';
import { ViewEmployee } from './ViewEmployee';

import { InvitationStatus, EmployeeStatus } from '../../utils/constants';

import axios from 'axios';

import { UserUI, EmployeeLists } from '../../utils/data_types';

import { parseJSON } from '../../utils/utils';

import { prompt_cost, completion_cost } from '../../utils/constants';


export interface IEmployeesProps {
    user?: UserUI;
    bearerToken: string;
    entityId?: string;
}
export const Employees: React.FC<IEmployeesProps> = (parent) => {
    const { Option } = Select;
    const { Column } = Table;


    const [employees, setEmployees] = useState<EmployeeLists[]>();
    const [viewNewClient, setViewNewClient] = useState(false);
    const [viewEmployee, setViewEmployee] = useState<EmployeeLists>();
    const [costsTable, setCostsTable] = useState<Record<string, any>>({});

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => {
        
        axios.post(
            `${azure_function_url}/NCID?command=employees_list`,
            {
                entityid: parent?.entityId || parent?.user?.entity?.id
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const res: EmployeeLists[] = parseJSON(x.data);
            setEmployees(res);
            const ids = res.map(x=>x.id).concat([parent.user?.id || '']).filter(x=>x).filter(x=>x !== '');
            axios.post(
                `${azure_function_url}/LLMModel?command=costs_get`,
                {
                    ids: ids
                },
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(x => {
                setCostsTable(x.data.reduce((acc:any, item:any) => {
                    acc[item['userid']] = item;
                    return acc;
                }, {} as Record<string, any>));
            });

        });
    }

    useEffect(() => { 
        update();
    }, []);
    
    
    return (
        viewEmployee ?
            <ViewEmployee bearerToken={parent.bearerToken} done={() => { setViewEmployee(undefined); }} employee={viewEmployee} user={parent.user}/>
        :
        viewNewClient === true ?
            <NewEmployee bearerToken={parent.bearerToken} done={() => { update();  setViewNewClient(false)}} user={parent.user} entityId={parent?.entityId} />
        :        
        employees?.length === 0 ?
        <Card 
            // bordered={false} 
            title={'Members'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<UserAddOutlined/>} onClick={() => setViewNewClient(true) }></Button>    
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
            bodyStyle={{ padding: 0 }}
        ></Card>
        :
        <Card 
            title={'Members'}
            extra={
                    <Space>
                        <Button style={{float:'right'}} icon={<UserAddOutlined/>} onClick={() => setViewNewClient(true) }></Button> 
                        <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                    </Space>
                }
        >
            
            <Table 
                dataSource={employees?.map((x: any, i: number) => {
                    let y: any = x;
                    y.key = i;
                    return y;
                })}
                pagination={{hideOnSinglePage: true}}
            >
                <Column
                    title="Email"
                    key="email"
                    render={(_: any, record: any) => record.email}
                />
                <Column
                    title="First"
                    key="first"
                    render={(_: any, record: any) => record.data?.personal.name.firstName}
                />
                <Column
                    title="Last"
                    key="last"
                    render={(_: any, record: any) => record.data?.personal.name.lastName}
                />
                
                <Column
                    title="Permission"
                    key="permission"
                    render={(_: any, record: any) => (
                        <Select
                            disabled={record.status !== InvitationStatus.ACCEPTED}
                            style={{ width: '100%' }}
                            optionLabelProp="label"
                            placeholder={'Pick a permission'}
                            onChange={(value) => {
                                console.log(value)
                                axios.post(
                                    `${azure_function_url}/NCID?command=employees_permission`,
                                    {
                                        entityid: parent?.entityId || parent.user?.entity?.id,
                                        employeeid: record.id,
                                        permission: value
                                    },
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(x => {
                                    message.success('Permission changed')
                                    update();
                                });
                            }}
                            value={record.permission}
                        >
                            {
                                [
                                    {type: EmployeeStatus.ADMIN, name: 'Admin'},
                                    {type: EmployeeStatus.WORKER, name: 'Member'},
                                    {type: EmployeeStatus.DENIED, name: 'Denied'}
                                ].map((template, i) => 
                                    <Option value={template.type} label={template.name} key={i}>
                                        <Space>
                                            {template.name}
                                        </Space>
                                    </Option>
                                )
                            }
                        </Select>
                    )}
                />
                <Column
                    title="Costs"
                    key="action"
                    render={(_: any, record: any) => (
                        record.status === InvitationStatus.WAITING ?
                        <>
                        <Popconfirm
                            title="Remind the member"
                            description="Are you sure to remind this member?"
                            // onConfirm={confirm}
                            // onCancel={cancel}
                            onConfirm={async () => { 
                                message.success('email sent...');
                                // axios.get(
                                //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                //     {
                                //         maxContentLength: Number.POSITIVE_INFINITY,
                                //         headers: {
                                //             'Authorization': parent.bearerToken
                                //         }
                                //     }
                                // ).then((data) => {
                                //     setViewResponse(data.data);
                                // });
                            } }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button 
                                type="default" 
                                block
                                icon={<LoadingOutlined style={{ fontSize: '16px' }}/>}
                            >
                                Remind
                            </Button>
                        </Popconfirm>
                        </>
                        :
                        costsTable[record.id] ? `AUD ${(parseInt(costsTable[record.id]?.prompt_tokens) * prompt_cost + parseInt(costsTable[record.id]?.completion_tokens) * completion_cost).toFixed(2)}` : 'AUD 0'
                    )}
                />
                <Column
                    title="Delete"
                    key="delete"
                    render={(_: any, record: any) => 
                        <Popconfirm
                            title="Delete the client"
                            description="Are you sure to delete this client?"
                            // onConfirm={confirm}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                        </Popconfirm>}
                />
            </Table>
        
        </Card>
    )
}